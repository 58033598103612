import { InitialStateType, AppAction } from './type';

export const mainReducer = (state: InitialStateType, action: AppAction) => {
  const { type, payload } = action;
  switch (type) {
    case 'login':
      const { isAuth, user, accessToken, refreshAccessToken } = payload;
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshAccessToken);
      localStorage.setItem('user', JSON.stringify(user));
      return { ...state, isAuth, user };
    case 'logout':
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user');
      localStorage.removeItem('forms');
      localStorage.removeItem('faqs');
      window.location.href = '/login';
      return { ...state, isAuth: false };
    case 'loading':
      return { ...state, loading: payload };
    case 'form':
      let formTemplate: any[] = [];
      payload.forEach((template: any) => {
        let form: any = {};
        let steps: any[] = [];
        template.inputAreaList.forEach((list: any, index: number) => {
          const { name, enName, remark, tips, description, formItemList } = list;
          let keys: any = {};
          formItemList?.forEach((item: any) => {
            if (item.groupName) {
              if (!form[item.groupName]) {
                form[item.groupName] = [];
              }
              form[item.groupName].push({
                ...item,
                step: index,
              });
            } else {
              form[item.dataField] = { ...item, step: index };
            }
            keys[item.dataField] = {
              parentField: item.parentField,
              parentValue: item.parentValue,
              groupName: item.groupName,
              required: item.required,
              comments: [],
              attachs: [],
            };
          });
          steps.push({
            name,
            enName,
            remark,
            tips,
            description,
            status: 'wait',
            keys,
            showKeys: [],
          });
        });
        formTemplate.push({ form, steps, name: template.name, linkageDefaultValue: template.linkageDefaultValue });
      });
      localStorage.setItem('forms', JSON.stringify(formTemplate));
      return { ...state, formTemplate };
    case 'supplier':
      return { ...state, supplier: payload };
    case 'menu':
      const { menuKey, pageTitle } = payload;
      return { ...state, menuKey, pageTitle };
    case 'faqs':
      localStorage.setItem('faqs', JSON.stringify(payload));
      return { ...state, faqs: payload };
    default:
      throw new Error();
  }
};
