import { useState } from 'react';
import {
  Modal,
  Button,
  Input,
  Form,
  message as Message,
} from 'antd';
import { forceChangePassword } from '../api';

const ForceChangePassword = (props: any) => {
  const [changePasswordForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePassword  = (values: any) => {
    const { loginId, oldPwd, newPwd } = values;
    setIsLoading(true);
    forceChangePassword({
      loginId,
      oldPwd,
      newPwd,
    }).then((response: any) => {
      const { status, message } = response;
      if (status === 1) {
        Message.success('Password has been changed, please log in with new password.');
        setIsLoading(false);
        props.closeModal();
      } else {
        setIsLoading(true);
        Message.error(message);
      }
    });
  };
  return (
    <Modal
      title={props.title}
      visible={props.visible}
      onCancel={props.closeModal}
      maskClosable={false}
      footer={[
        <Button key='cancle' onClick={props.closeModal}>
          Cancle
        </Button>,
        <Button form='changepassword' key='submit' type='primary' htmlType='submit' loading={isLoading}>
          Save
        </Button>,
      ]}
      >
      <Form id='changepassword' form={changePasswordForm} onFinish={handleChangePassword}>
        <Form.Item
          name='loginId'
          label='Account name'
          rules={[
            {
              required: true,
              message: 'Please input your account name!',
            },
          ]}
        >
          <Input placeholder='please input' />
        </Form.Item>
        <Form.Item
          name='oldPwd'
          label='Old password'
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
            {
              pattern: new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{10,20}'),
              message: '密码需包含大写字母、小写字母、数字、特殊符号, 长度为10-20',
            },
          ]}
        >
          <Input.Password placeholder='please input' />
        </Form.Item>
        <Form.Item
          name='newPwd'
          label='New password'
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
            {
              pattern: new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{10,20}'),
              message: '密码需包含大写字母、小写字母、数字、特殊符号, 长度为10-20',
            },
          ]}
        >
          <Input.Password placeholder='please input' />
        </Form.Item>
        <Form.Item
          name='confirmPwd'
          label='Confirm password'
          dependencies={['newPwd']}
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPwd') === value) {
                  return Promise.resolve();
                }
  
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder='please input' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ForceChangePassword;
