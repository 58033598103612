import { useReducer } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { AppContext, initialState, mainReducer } from './common';
import { Home, Login, InitUser } from './pages';

const App = () => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={<Home />} />
          <Route path='auth' element={<Login />} />
          <Route path='inituser' element={<InitUser />} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export default App;
