import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Layout,
  Form,
  Input,
  Button,
  Select,
  Card,
  message as Message,
} from 'antd';
import {
  getSecurityQuestion,
  saveInitUserInfo,
} from './../api';
import Logo from './../images/logo.svg';
import styles from './../scss/modules/login.module.scss';
import { useQuery, AppContext } from '../common';

interface OptionType {
  label: React.ReactNode;
  value?: string | number | null;
  disabled?: boolean;
}

const InitUser = () => {
  const { state } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { Content, Footer } = Layout;
  const initusercode = useQuery().get('code') || '';
  const [initUserForm] = Form.useForm();
  const [securityQuestions1, setSecurityQuestion1] = useState<Array<OptionType>>();
  const [securityQuestions2, setSecurityQuestion2] = useState<Array<OptionType>>();
  const [securityQuestions3, setSecurityQuestion3] = useState<Array<OptionType>>();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.isAuth) {
      navigate('/');
      return;
    } if (initusercode) {
      initUserForm.resetFields();
      handleGetSecurityQuestion();
    } else {
      navigate('/');
    }
  }, []);

  const handleGetSecurityQuestion = () => {
    getSecurityQuestion().then((response: any) => {
      console.log(response);
      setSecurityQuestion1(response);
      setSecurityQuestion2(response);
      setSecurityQuestion3(response);
      setLoading(false);
    }).catch(() => {
      setSecurityQuestion1(undefined);
      setSecurityQuestion2(undefined);
      setSecurityQuestion3(undefined);
    });
  };

  
  const handleSecurityQuestion1Change = (value: string) => {
    setOptionDisabled(securityQuestions2, setSecurityQuestion2);
    setOptionDisabled(securityQuestions3, setSecurityQuestion3);
  };

  const handleSecurityQuestion2Change = (value: string) => {
    setOptionDisabled(securityQuestions1, setSecurityQuestion1);
    setOptionDisabled(securityQuestions3, setSecurityQuestion3);
  };
  const handleSecurityQuestion3Change = (value: string) => {
    setOptionDisabled(securityQuestions1, setSecurityQuestion1);
    setOptionDisabled(securityQuestions2, setSecurityQuestion2);
  };

  const setOptionDisabled = (options: Array<OptionType> | undefined, setSecurityQuestion: any) => {
    let question1Id = initUserForm.getFieldValue('question1Id');
    let question2Id = initUserForm.getFieldValue('question2Id');
    let question3Id = initUserForm.getFieldValue('question3Id');
    if (options) {
      options.forEach((element, index) => {
        options[index].disabled = false;
        if (element.value === question1Id || element.value === question2Id || element.value === question3Id) {
          options[index].disabled = true;
        }
      });
      setSecurityQuestion(options);
    }
  };

  
  const handleInitUserInfo = (values: any) => {    
    setLoading(true);
    saveInitUserInfo({ ...values, initUserCode : initusercode }).then((response: any) => {
      const { status, message } = response;
      if (status === 1) {
        Message.success('Account has been actived, please log in with new password.');        
        navigate('/auth');
        setLoading(false);
      } else {
        setLoading(false);
        Message.error(message);
      }
    });
  };

  const handleLogin = () => {
    navigate('/');
  };

  return (
    <div className='login'>
      <Layout className={styles.layout}>
        <Content className={styles.content}>
          <div className={styles.title}>
            <img className={styles.logo} src={Logo} alt='Supplier Auxiliary Governance e-form' />
          </div>
          <Card title="Initialize user information" bordered={false} style={{ width: 600 }}>
            <Form id='inituser' form={initUserForm} onFinish={handleInitUserInfo}>
              <Form.Item
                name='verifyCode'
                label='Verify Code'
                rules={[
                  {
                    required: true,
                    message: 'Please input your verify code!',
                  },
                ]}
              >
                <Input placeholder='please input' />
              </Form.Item>
              <Form.Item
                name='question1Id'
                label='Security Question 1'
                rules={[
                  {
                    required: true,
                    message: 'Please select your security suestion!',
                  },
                ]}
              >
                <Select options={securityQuestions1} onChange={handleSecurityQuestion1Change} allowClear={true} placeholder='please select' />
              </Form.Item>
              <Form.Item
                name='answer1'
                label='Security Answer 1'
                rules={[
                  {
                    required: true,
                    message: 'Please input your answer!',
                  },
                ]}
              >
                <Input placeholder='please input' />
              </Form.Item>
              <Form.Item
                name='question2Id'
                label='Security Question 2'
                rules={[
                  {
                    required: true,
                    message: 'Please select your security suestion!',
                  },
                ]}
              >
                <Select options={securityQuestions2} allowClear={true} onChange={handleSecurityQuestion2Change} placeholder='please select' />
              </Form.Item>
              <Form.Item
                name='answer2'
                label='Security Answer 2'
                rules={[
                  {
                    required: true,
                    message: 'Please input your answer!',
                  },
                ]}
              >
                <Input placeholder='please input' />
              </Form.Item><Form.Item
                name='question3Id'
                label='Security Question 3'
                rules={[
                  {
                    required: true,
                    message: 'Please select your security suestion!',
                  },
                ]}
              >
                <Select options={securityQuestions3} allowClear={true} onChange={handleSecurityQuestion3Change} placeholder='please select' />
              </Form.Item>
              <Form.Item
                name='answer3'
                label='Security Answer 3'
                rules={[
                  {
                    required: true,
                    message: 'Please input your answer!',
                  },
                ]}
              >
                <Input placeholder='please input' />
              </Form.Item>
              <Form.Item
                name='newPassword'
                label='New password'
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    pattern: new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{10,20}'),
                    message: '密码需包含大写字母、小写字母、数字、特殊符号, 长度为10-20',
                  },
                ]}
              >
                <Input.Password placeholder='please input' />
              </Form.Item>
              <Form.Item
                name='confirmPwd'
                label='Confirm password'
                dependencies={['newPwd']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
        
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder='please input' />
              </Form.Item>
              <Form.Item>
                <Button type='primary' htmlType='submit' block size='large' loading={loading}>
                  Save
                </Button>
              </Form.Item>
              <Form.Item>
                <Button  block size='large' onClick={handleLogin}>
                  Sign in instead
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Content>
        <Footer className={styles.footer}>All contents © Copyright Xian Janssen Pharmaceutical Ltd. 2012-2022. All Rights Reserved</Footer>
      </Layout>
    </div>
  );
};

export default InitUser;
