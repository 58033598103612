import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  Card,
} from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import styles from './../scss/modules/item.module.scss';

export const FormList = (props: any) => {
  const { name, childrenTemplate, value = [], disabled } = props;
  const [items, setItems] = useState([ ...value ]);
  const { Item } = Form;

  const addList = (add: any) => {
    const newList = [...items];
    const newTemplate = childrenTemplate.map((templateField: any)=> {
      return { ...templateField, defaultValue: null };
    });
    newList.push([ ...newTemplate ]);
    setItems(newList);
    add();
  };

  const removeList = (targetIndex: number, remove: any) => {
    const newList = [ ...items ];
    newList.splice(targetIndex, 1);
    setItems(newList);
    remove(targetIndex);
  };

  useEffect(() => {
    setItems([ ...value ]);
  }, [value]);

  return (
    <Form.List name={name}>
    {(fields, { add, remove }) => (
      <div key={name} >
        { items?.map((item: any, index: number) => {
          return (
            <span key={`${name}_${index}`} className={`${styles.item} ${props.isHidden ? styles.hidden : styles.visible}`} >
              <Card
                style={{ padding: 0 }}
                type="inner"
                title={`SupplierInfo${index + 1}`}
                extra={index === 0
                  ? null
                  : <MinusCircleOutlined 
                      onClick={() => removeList(index, remove)}
                    />
                }
              >
                {
                  childrenTemplate.map((childFieldArray: any) => (
                    childFieldArray.map((childField: any) => {
                      const { options, enLabel, required, label, tips, maxLength, type, parentField, parentValue, dataField } = childField;
                      let isHidden = false;
                      if (parentField && parentValue) {
                        if (!item || !item[parentField]) {
                          if (dataField.includes('supplierGroup1')) {
                            isHidden = false;
                          } else { 
                            isHidden = true;
                          }
                        } else {
                          if (item[parentField] === parentValue) {
                            isHidden = false;
                          } else {
                            isHidden = true;
                          }
                        }
                      } else {
                        isHidden = false;
                      }
                      const config = {
                        label: enLabel + ' ' + label,
                        tooltip: tips ? () => <div>{tips.split('\r\n').map((tip: string, idx: number) => <span key={idx}>{tip}<br /></span>)}</div> : tips,
                        name: [index, dataField],
                        rules: [{
                          required: required && !childField.isHidden,
                          message: 'this field is required',
                        }],
                      };
                      return (
                        <>
                          {
                            !isHidden && (
                            <div className={styles.formListItem} key={`${index}_${dataField}`}>
                              {type === 'textarea' && <Item {...config}><Input.TextArea autoSize disabled={disabled} maxLength={maxLength} allowClear={true} showCount={!disabled} placeholder='please input...' /></Item>}
                              {type === 'select' && <Item {...config}><Select disabled={disabled} options={options} allowClear={true} placeholder='please select' /></Item>}
                              {type === 'multiple-select' && <Item {...config}><Select disabled={disabled} mode='multiple' options={options} allowClear={true} placeholder='please select' /></Item>}
                            </div>
                            )
                          }
                        </>
                      );
                    })
                  ))
                }
              </Card>
            </span>
          );
        })}
        <Item className={`${styles.item} ${props.isHidden ? styles.hidden : styles.visible}`}>
          <Button type="dashed"
            onClick={() => addList(add)}
            block
            icon={<PlusOutlined />}
          >
            Add SupplierInfo
          </Button>
        </Item>
      </div>
    )}
  </Form.List>
  );
};

