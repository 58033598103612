import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  Badge,
} from 'antd';
import styles from './../scss/modules/list.module.scss';
import { ColumnsType } from 'antd/es/table';
import { getMyTasks } from './../api';
import { AppContext } from '../common';

const Tasks = () => {
  const { state, dispatch } = useContext(AppContext);
  const [formList, setFormList] = useState<any>({
    entityTotalCount: 0,
    entities: [],
  });
  const [queryModel, setQueryModel] = useState<any>({
    pageCount: 10,
    pageIndex: 1,
  });
  const [loading, setLoading] = useState(true);
  const columns: ColumnsType<any> = [
    {
      title: 'Eform type',
      dataIndex: 'eformType',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      render: (text, item) => <Link to={`/supplier?eFormType=${item.eformType}&id=${item.id}`}>{text || 'Supplier'}</Link>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: () => <Badge status='processing' text='Under review' />,
    },
    {
      title: 'Update time',
      dataIndex: 'updateTime',
    },
    {
      title: 'Requestor',
      dataIndex: 'updateUserName',
    },
  ];

  useEffect(() => {
    if (state.isAuth) {
      dispatch({ type: 'menu', payload: { menuKey: 'tasks', pageTitle: 'My tasks' } });
      setLoading(true);
      getMyTasks({
        pageIndex: queryModel.pageIndex,
        pageCount: queryModel.pageCount,
      }).then((response: any) => {
        const { model } = response;
        setFormList({ ...model });
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [queryModel, state.isAuth]);

  const handleTableChange = (pagination: any) => {
    setQueryModel({
      pageIndex: pagination.current,
      pageCount: pagination.pageSize,
    });
  };

  return (
    <div className='Tasks'>
      <div className={styles.wrapper}>
        <Table
          className={styles.table}
          loading={loading}
          rowKey={(record) => `${record.eformType}_${record.id}`}
          columns={columns}
          dataSource={formList.entities}
          pagination={{
            pageSize: queryModel.pageCount,
            current: queryModel.pageIndex,
            total: formList.entityTotalCount,
            hideOnSinglePage: true,
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default Tasks;
