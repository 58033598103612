import Icon from '@ant-design/icons';

const LogoutIcon = () => {
  const LogoutSvg = () => (
    <svg width='16px' height='16px' viewBox='0 0 16 16'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='List' transform='translate(-1400.000000, -17.000000)' fill='#000000' fillRule='nonzero'>
              <g id='logout' transform='translate(1400.000000, 17.000000)'>
                  <rect id='Rectangle' opacity='0' x='0' y='0' width='16' height='16'></rect>
                  <path d='M2,3.33333333 C2,2.6 2.6,2 3.33333333,2 L8.66666667,2 L8.66666667,3.33333333 L3.33333333,3.33333333 L3.33333333,12.6666667 L8.66666667,12.6666667 L8.66666667,14 L3.33333333,14 C2.6,14 2,13.4 2,12.6666667 L2,3.33333333 Z M11.4506667,7.33333333 L9.76,5.64266667 L10.7026667,4.7 L14.0026667,8 L10.7026667,11.3 L9.76,10.3573333 L11.4506667,8.66666667 L7.06,8.66666667 L7.06,7.33333333 L11.4506667,7.33333333 L11.4506667,7.33333333 Z' id='Shape' fillOpacity='0.45'></path>
              </g>
          </g>
      </g>
    </svg>
  );

  return <Icon component={LogoutSvg} />;
};

export default LogoutIcon;
