import { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  Modal,
  message as Message,
} from 'antd';
import {
  getSecurityQuestion,
  saveUserSecurityQuestion,
} from '../api';

interface OptionType {
  label: React.ReactNode;
  value?: string | number | null;
  disabled?: boolean;
}

const SetSecurityQuestions = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [setSecurityQuestionsForm] = Form.useForm();
  const [securityQuestions1, setSecurityQuestion1] = useState<Array<OptionType>>();
  const [securityQuestions2, setSecurityQuestion2] = useState<Array<OptionType>>();
  const [securityQuestions3, setSecurityQuestion3] = useState<Array<OptionType>>();

  useEffect(() => {
    setSecurityQuestionsForm.resetFields();
    handleGetSecurityQuestion();
  }, []);

  const handleGetSecurityQuestion = () => {
    setLoading(true);
    getSecurityQuestion().then((response: any) => {
      console.log(response);
      setSecurityQuestion1(response);
      setSecurityQuestion2(response);
      setSecurityQuestion3(response);
      setLoading(false);
    }).catch(() => {
      setSecurityQuestion1(undefined);
      setSecurityQuestion2(undefined);
      setSecurityQuestion3(undefined);
    });
  };
  
  const handleSecurityQuestion1Change = (value: string) => {
    setOptionDisabled(securityQuestions2, setSecurityQuestion2);
    setOptionDisabled(securityQuestions3, setSecurityQuestion3);
  };

  const handleSecurityQuestion2Change = (value: string) => {
    setOptionDisabled(securityQuestions1, setSecurityQuestion1);
    setOptionDisabled(securityQuestions3, setSecurityQuestion3);
  };
  const handleSecurityQuestion3Change = (value: string) => {
    setOptionDisabled(securityQuestions1, setSecurityQuestion1);
    setOptionDisabled(securityQuestions2, setSecurityQuestion2);
  };

  const setOptionDisabled = (options: Array<OptionType> | undefined, setSecurityQuestion: any) => {
    let question1Id = setSecurityQuestionsForm.getFieldValue('question1Id');
    let question2Id = setSecurityQuestionsForm.getFieldValue('question2Id');
    let question3Id = setSecurityQuestionsForm.getFieldValue('question3Id');
    if (options) {
      options.forEach((element, index) => {
        options[index].disabled = false;
        if (element.value === question1Id || element.value === question2Id || element.value === question3Id) {
          options[index].disabled = true;
        }
      });
      setSecurityQuestion(options);
    }
  };
  
  const handleSetSecurityQuestions = (values: any) => {    
    setLoading(true);
    saveUserSecurityQuestion({ ...values }).then((response: any) => {
      const { status, message } = response;
      if (status === 1) {
        Message.success('Success!');
        setLoading(false);
        props.closeModal();
      } else {
        Message.error(message);
        setLoading(false);
      }
    }).catch(() => {
      setLoading(false);
    });
  };

  return (
      <Modal
        title={props.title}
        visible={props.visible}
        onCancel={props.closeModal}
        maskClosable={false}
        footer={[
          <Button key='cancle' onClick={props.closeModal}>
            Cancle
          </Button>,
          <Button form='setSecurityQuestionsForm' key='submit' type='primary' htmlType='submit' loading={loading}>
            Submit
          </Button>,
        ]}
        >
          <Form id='setSecurityQuestionsForm' form={setSecurityQuestionsForm} onFinish={handleSetSecurityQuestions}>
            <Form.Item
              name='question1Id'
              label='Security Question 1'
              rules={[
                {
                  required: true,
                  message: 'Please select your security suestion!',
                },
              ]}
            >
              <Select options={securityQuestions1} onChange={handleSecurityQuestion1Change} allowClear={true} placeholder='please select' />
            </Form.Item>
            <Form.Item
              name='answer1'
              label='Security Answer 1'
              rules={[
                {
                  required: true,
                  message: 'Please input your answer!',
                },
              ]}
            >
              <Input placeholder='please input' />
            </Form.Item>
            <Form.Item
              name='question2Id'
              label='Security Question 2'
              rules={[
                {
                  required: true,
                  message: 'Please select your security suestion!',
                },
              ]}
            >
              <Select options={securityQuestions2} allowClear={true} onChange={handleSecurityQuestion2Change} placeholder='please select' />
            </Form.Item>
            <Form.Item
              name='answer2'
              label='Security Answer 2'
              rules={[
                {
                  required: true,
                  message: 'Please input your answer!',
                },
              ]}
            >
              <Input placeholder='please input' />
            </Form.Item>
            <Form.Item
              name='question3Id'
              label='Security Question 3'
              rules={[
                {
                  required: true,
                  message: 'Please select your security suestion!',
                },
              ]}
            >
              <Select options={securityQuestions3} allowClear={true} onChange={handleSecurityQuestion3Change} placeholder='please select' />
            </Form.Item>
            <Form.Item
              name='answer3'
              label='Security Answer 3'
              rules={[
                {
                  required: true,
                  message: 'Please input your answer!',
                },
              ]}
            >
              <Input placeholder='please input' />
            </Form.Item>
          </Form>
      </Modal>
  );
};

export default SetSecurityQuestions;
