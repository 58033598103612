import { useState, useEffect, useContext } from 'react';
import {
  Form,
  Select,
  DatePicker,
  Button,
  Table,
} from 'antd';
import moment from 'moment';
import styles from './../scss/modules/list.module.scss';
import { ColumnsType } from 'antd/es/table';
import { AppContext, LogFormList, LogTableItem } from '../common';
import { getLogList } from './../api';

const columns: ColumnsType<LogTableItem> = [
  {
    title: 'Eform type',
    dataIndex: 'formName',
  },
  {
    title: 'Supplier',
    dataIndex: 'supplierName',
    render: (text: string) => text || 'Supplier',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    filters: [
      {
        text: 'Add Form Data',
        value: 1,
      },
      {
        text: 'Edit',
        value: 2,
      },
      {
        text: 'Delete',
        value: 3,
      },
      {
        text: 'Submit',
        value: 4,
      },
      {
        text: 'Approve',
        value: 5,
      },
      {
        text: 'Reject',
        value: 6,
      },
      {
        text: 'Reopen',
        value: 7,
      },
      {
        text: 'Add Comment',
        value: 8,
      },
      {
        text: 'Restore',
        value: 9,
      },
      {
        text: 'Upload Attachment',
        value: 10,
      },
      {
        text: 'Delete Attachment',
        value: 11,
      },
      {
        text: 'Export Report',
        value: 12,
      },
    ],
    render: (text, item) => (`${item.actionTypeName} (${item.updateUserName})`),
  },
  {
    title: 'Update time',
    dataIndex: 'updateTime',
    sorter: true,
  },
  {
    title: 'Remark',
    dataIndex: 'remark',
  },
];

const Logs = () => {
  const { state, dispatch } = useContext(AppContext);
  const { Item } = Form;
  const [form] = Form.useForm();
  const [queryModel, setQueryModel] = useState<any>({
    StartDate: '',
    EndDate: '',
    FormId: '',
    UserId: '',
    ActionType: '',
    SortBy: 'updateTime',
    AscOrDesc: 'desc',
    pageCount: 10,
    pageIndex: 1,
  });
  const [loading, setLoading] = useState(true);
  const [formList, setFormList] = useState<LogFormList>({
    entityTotalCount: 0,
    entities: [],
  });

  useEffect(() => {
    if (state.isAuth) {
      dispatch({ type: 'menu', payload: { menuKey: 'logs', pageTitle: '日志' } });
      setLoading(true);
      getLogList({
        pageIndex: queryModel.pageIndex,
        pageCount: queryModel.pageCount,
        'QueryModel.StartDate': queryModel.StartDate,
        'QueryModel.EndDate': queryModel.EndDate,
        'QueryModel.FormId': queryModel.FormId,
        'QueryModel.UserId': queryModel.UserId,
        'QueryModel.ActionType': queryModel.ActionType,
        'SortModel.SortBy': queryModel.SortBy,
        'SortModel.AscOrDesc': queryModel.AscOrDesc,
      }).then((response: any) => {
        const { model } = response;
        model.entities.map((item: any, index: number) => { item.key = index; return item; });
        setFormList({ ...model });
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [queryModel, state.isAuth]);

  const handleSearch = (values: any) => {
    values.StartDate = values.StartDate ? moment(values.StartDate).format('YYYY-MM-DD') : '';
    values.EndDate = values.EndDate ? moment(values.EndDate).format('YYYY-MM-DD') : '';

    setQueryModel({
      ...queryModel,
      ...values,
      pageIndex: 1,
    });
  };

  const handleReset = () => {
    form.resetFields();
    setQueryModel({
      ...queryModel,
      StartDate: '',
      EndDate: '',
      FormId: '',
      UserId: '',
      ActionType: '',
      SortBy: 'updateTime',
      AscOrDesc: 'desc',
      pageIndex: 1,
    });
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setQueryModel({
      ...queryModel,
      pageIndex: pagination.current,
      pageCount: pagination.pageSize,
      ActionType: filters.action ? filters.action.join() : '',
      AscOrDesc: sorter && sorter.order === 'ascend' ? 'asc' : 'desc',
    });
  };

  return (
    <div className='List'>
      <div className={styles.wrapper}>
        <Form
          form={form}
          name='listSearch'
          className={styles.search}
          onFinish={handleSearch}
        >
          <div className={styles.item_group}>
            <Item
              name='StartDate'
              label='Start Date'
              className={styles.item}
            >
              <DatePicker />
            </Item>
            <Item
              name='EndDate'
              label='End Date'
              className={styles.item}
            >
              <DatePicker />
            </Item>
            <Item
              name='FormId'
              label='Eform type'
              className={styles.item}
            >
              <Select
                placeholder='please select'
                options={[{
                  label: 'All',
                  value: '',
                }, {
                  label: 'TV-eFRM-16853',
                  value: 1,
                }, {
                  label: 'TV-eFRM-16856',
                  value: 2,
                }]}
              />
            </Item>
          </div>
          <div className={styles.btn_group}>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
            <Button
              style={{ marginLeft: '8px' }}
              loading={loading}
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </Form>
        <Table
          className={styles.table}
          loading={loading}
          columns={columns}
          dataSource={formList.entities}
          pagination={{
            pageSize: queryModel.pageCount,
            current: queryModel.pageIndex,
            total: formList.entityTotalCount,
            hideOnSinglePage: true,
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default Logs;
