import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Steps,
  Form,
  Input,
  Button,
  Select,
  Modal,
  Drawer,
  Timeline,
  Upload,
  Checkbox,
  message as Message,
} from 'antd';
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
  SafetyOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import jsFileDownload from 'js-file-download';
import styles from './../scss/modules/form.module.scss';
import { AppContext, useQuery } from './../common';
import FormItem from './FormItem';
import {
  getSupplierDetail,
  saveSupplierDraft,
  getAllReviewers,
  submitSupplier,
  changeSupplierStatus,
  getCommentList,
  saveComment,
  getAttachList,
  getAttachDetailList,
  fileUpload,
  fileDownload,
  fileDelete,
  exportSupplier,
  validateSupplier,
  getCaptcha,
} from './../api';

const Supplier = () => {
  let supplierId = useQuery().get('id') || '';
  const eFormType = useQuery().get('eFormType') || '';
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const { Item } = Form;
  const { Step } = Steps;
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [init, setInit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [codeBase64Img, setCodeBase64Img] = useState('');
  const [captchaId, setCaptchaId] = useState('');
  const [template, setTemplate] = useState<any>({});
  const [supplierTemplate, setSupplierTemplate] = useState<any>([]);
  const [linkageDefaultValue, setLinkageDefaultValue] = useState<any>({});
  const [steps, setSteps] = useState<any[]>([]);
  const [commentList, setCommentList] = useState<any[]>([]);
  const [commentField, setCommentField] = useState('');
  const [fileList, setFileList] = useState<any[]>([]);
  const [approverList, setApproverList] = useState<any[]>([]);
  const [current, setCurrent] = useState(0);
  const [formValues, setFormValues] = useState<any>({});
  const [isChange, setIsChange] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [modalProps, setModalProps] = useState<any>({
    title: 'Add comment',
    okText: 'Save',
    footer: null,
    type: 'add',
    dataField: '',
  });

  const [resend, setResend] = useState<any>(eFormType === 'TV-eFRM-16853' ? {
    LSO: false,
    QL: false,
  } : {
    'Quality function': false,
    'PV function': false,
  });

  // process supplierInfo fields structure.
  const processSupplierInfo = (listForm: any) => {
    const newListForm = listForm;
    if (newListForm.SupplierInfo && _.isArray(newListForm.SupplierInfo)) {
      const supplierList = newListForm.SupplierInfo;
      delete newListForm.SupplierInfo;
      const supplierInfo = supplierList.find((item: any) => item.dataField === 'supplierInfo');
      const supplierChild = supplierList.filter((item: any) => item.dataField !== 'supplierInfo');
      supplierInfo.childrenTemplate = [];
      supplierInfo.childrenTemplate.push([ ...supplierChild ]);
      newListForm.SupplierInfo = { ...supplierInfo };
      setSupplierTemplate(supplierChild);
      return newListForm;
    }
    return newListForm;
  };

  // get form template
  useEffect(() => {
    window.scrollTo(0, 0);

    if (!eFormType) {
      navigate('/404');
      return;
    }

    if (!supplierId && state.user.role !== 2) {
      navigate('/403');
      return;
    }
  
    if (state.formTemplate.length) {
      const list = state.formTemplate.find((item: any) => item.name === eFormType);
      if (list) {
        dispatch({ type: 'menu', payload: { menuKey: supplierId ? '' : eFormType, pageTitle: eFormType } });
        form.resetFields();
        setLinkageDefaultValue({ ...list.linkageDefaultValue });
        const templateForm = processSupplierInfo(list.form);
        setTemplate({ ...templateForm });
        list.steps.map((item: any) => {
          item.status = 'wait';
          _.values(item.keys).forEach((child: any) => {
            child.comments = [];
            child.attachs = [];
          });
          return item;
        });
        setSteps([...list.steps]);
      } else {
        navigate('/404');
        return;       
      }
    }

    return () => {
      setInit(false);
      setIsChange(false);
      setIsModalVisible(false);
      setIsDrawerVisible(false);
      setSteps([]);
      setFormValues({});
      setCommentField('');
      setCurrent(0);
      setFileList([]);
      setCommentList([]);
      setApproverList([]);
      setTemplate({});
      setSupplierTemplate([]);
      dispatch({
        type: 'supplier',
        payload: {
          current: -100,
          role1: {
            name: '',
            status: 'wait',
            id: null,
            current: 0,
          },
          role2: {
            name: '',
            status: 'wait',
            id: null,
            current: 0,
          },
          action: '',
        },
      });
    };
  }, [eFormType, supplierId, state.formTemplate]);

  // get form info
  useEffect(() => {
    if (_.keys(template).length && !init) {
      if (supplierId) {
        dispatch({ type: 'loading', payload: true });
        getSupplierDetail(eFormType, { id: supplierId }).then(async (response: any) => {
          const { model, status, message } = response;
          if (status === 1) {
            const values: any = {};

            // set comment list
            setCommentList(model.commentList);

            // set form values
            _.values(template).forEach((item: any) => {
              if (_.isArray(item)) {
                item.forEach((child: any) => {
                  if (child.type === 'date') {
                    values[child.dataField] = model[child.dataField] ? moment(model[child.dataField]) : child.value;
                  } else {
                    values[child.dataField] = model[child.dataField];
                  }
                });
              } else {
                if (item.type === 'date') {
                  values[item.dataField] = model[item.dataField] ? moment(model[item.dataField]) : null;
                } else {
                  values[item.dataField] = model[item.dataField];
                }
              }
            });
            setFormValues({ ...values });
            form.setFieldsValue(values);

            let action = '';
            if (state.user.role === 1) {
              action = model.status > -1 ? 'delete' : 'restore';
            } else if (state.user.role === 2 && model.createUserId === state.user.id) {
              if ([0, 4].indexOf(model.status) !== -1) {
                action = 'editor';
              } else if (model.status === 3) {
                action = 'reopen';
              } else {
                action = '';
              }
            } else if ([4, 5].indexOf(state.user.role) !== -1) {
              action = model.status === 1 && model.reviewer1Id === state.user.id ? 'review' : '';
            } else if ([3, 6].indexOf(state.user.role) !== -1) {
              action = '';
              if (model.status === 2 && model.reviewer2Id === state.user.id) {
                action = 'review';
              }
              if (state.user.role === 3 && model.status === 0) {
                action = 'ql_edit';
              }
            } else {
              action = '';
            }

            const progress: any = { 0: 'wait', 3: 'finish', 4: 'error' };
            let role1Status, role2Status;
            if (model.status === 1) {
              role1Status = 'process';
              role2Status = 'wait';
            } else if (model.status === 2) {
              role1Status = 'finish';
              role2Status = 'process';
            } else if (model.status === 3) {
              role1Status = 'finish';
              role2Status = 'finish';
            } else {
              role1Status = progress[model.reviewer1Status] || 'wait';
              role2Status = progress[model.reviewer2Status] || 'wait';
            }
            dispatch({
              type: 'supplier',
              payload: {
                current: model.status,
                role1: {
                  name: eFormType === 'TV-eFRM-16853' ? 'LSO' : 'Quality function',
                  status: role1Status,
                  id: model.reviewer1Id,
                  current: model.reviewer1Status,
                },
                role2: {
                  name: eFormType === 'TV-eFRM-16853' ? 'QL' : 'PV function',
                  status: role2Status,
                  id: model.reviewer2Id,
                  current: model.reviewer2Status,
                },
                action,
              },
            });
          
            // update steps status
            if (model.status > 0) {
              const list = [...steps];
              list.map((item: any) => {
                item.status = 'finish';
                return item;
              });
              setSteps([...list]);
            } else {
              handleChangeStepStatus(values);
            }

            // get comment list
            if (model.haveComments) {
              try {
                const res: any = await getCommentList(eFormType, { id: supplierId });
                if (res.status === 1) {
                  const list = [...steps];
                  list.map((item: any) => {
                    _.keys(item.keys).forEach((key: string) => {
                      if (_.isArray(res.model[key])) {
                        item.keys[key].comments = res.model[key];
                      }
                    });
                    return item;
                  });
                  setSteps([...list]);
                } else {
                  Message.error(res.message);
                }
              } catch (error) {
                console.log(error);
              }
            }

            // get form attach list
            if (model.haveAttachList) {
              try {
                const res: any = await getAttachList(eFormType, { id: supplierId });
                if (res.status === 1) {
                  res.model.map((item: any) => {
                    item.name = item.fileName;
                    item.status = 'done';
                    item.response = '{"status": "success"}';
                    return item;
                  });
                  setFileList([...res.model]);
                } else {
                  Message.error(res.message);
                }
              } catch (error) {
                console.log(error);
              }
            }

            // get filed attach list
            if (model.haveAttachDetail) {
              try {
                const res: any = await getAttachDetailList(eFormType, { id: supplierId });
                if (res.status === 1) {
                  const list = [...steps];
                  list.map((item: any) => {
                    _.keys(item.keys).forEach((key: string) => {
                      if (_.isArray(res.model[key])) {
                        item.keys[key].attachs = res.model[key].map((file: any) => {
                          file.dataField = key;
                          file.name = file.fileName;
                          file.status = 'done';
                          file.response = '{"status": "success"}';
                          return file;
                        });
                      }
                    });
                    return item;
                  });
                  setSteps([...list]);
                } else {
                  Message.error(res.message);
                }
              } catch (error) {
                console.log(error);
              }
            }
          } else if (status === 4) {
            Message.error(message);
            navigate('/403');
          } else {
            Message.error(message);
            navigate('/404');
          }
        }).finally(() => {
          dispatch({ type: 'loading', payload: false });
        });
      } else {
        dispatch({
          type: 'supplier',
          payload: {
            action: state.user.role === 2 ? 'editor' : '',
          },
        });
        const values: any = {};
        _.values(template).forEach((item: any) => {
          if (_.isArray(item)) {
            item.forEach((child: any) => {
              values[child.dataField] = setDefaultValue(child.defaultValue, child.type);
            });
          } else {
            if (item.type === 'group') {
              const childrenVal: any = {};
              values[item.dataField] = [];
              item.childrenTemplate.forEach((childItemArray: any) => {
                childItemArray.forEach((childItem: any) => {
                  childrenVal[childItem.dataField] =  setDefaultValue(childItem.defaultValue, childItem.type);
                });
              });
              values[item.dataField].push(childrenVal);
            } else {
              values[item.dataField] = setDefaultValue(item.defaultValue, item.type);
            }
          }
        });
        setFormValues({ ...values });
        form.setFieldsValue(values);
      }
      setInit(true);
    }
  }, [template]);

  useEffect(() => {
    if (steps.length) {
      const list = [...steps];
      list.map((item: any) => {
        item.showKeys = _.keys(item.keys).filter((key: string) => {
          const parentField = item.keys[key].parentField;
          return parentField ? formValues[parentField] === item.keys[key].parentValue : true;
        });
        return item;
      });
      setSteps([...list]);
      if (state.supplier.current === 0 && steps[current].status === 'error') {
        form.validateFields();
      }
    }
  }, [current, template]);

  const setDefaultValue = (value: any, type: string) => {
    if (type === 'date') {
      return value ? moment(value) : null; 
    } else if (type === 'switch') {
      return value === null ? false : value;
    } else if (type === 'multiple-select') {
      return value || [];
    } else {
      return value || null;
    }
  };

  const isFieldHidden = (item: any) => {
    if (item.parentField) {
      return _.isArray(formValues[item.parentField]) ? formValues[item.parentField].indexOf(item.parentValue) === -1 : formValues[item.parentField] !== item.parentValue;
    } else {
      return false;
    }
  };

  const handleExport = () => {
    exportSupplier(eFormType, { id: supplierId }).then((response: any) => {
      const { headers, data } = response;
      const fileName = decodeURIComponent(headers['content-disposition'].split(';')[1].replace('filename=', ''));
      jsFileDownload(data, fileName);
    });
  };

  const handleFileDownload = (file: any) => {
    fileDownload(eFormType, { id: supplierId, attachId: file.id }).then(({ data }: any) => {
      jsFileDownload(data, file.name);
    });
  };

  const handleFileRemove = (file: any) => {
    if (!file.id) return;
    fileDelete(eFormType, { id: supplierId, attachId: file.id }).then((response: any) => {
      const { status, message } = response;
      if (status === 1) {
        Message.success('Successed');
        if (file.dataField) {
          const list = [...steps];
          list.map((item: any, index: number) => {
            if (index === current) {
              item.keys[file.dataField].attachs = item.keys[file.dataField].attachs.filter((child: any) => child.id !== file.id);
            }
            return item;
          });
          setSteps([...list]);
        } else {
          setFileList([...fileList.filter((item: any) => item.id !== file.id)]);
        }
        return true;
      } else {
        Message.error(message);
        return false;
      }
    }).catch(() => {
      return false;
    });
  };

  const handleFileUpload = (config: any) => {
    const file = config.file;

    if (file.size > 5 * 1024 * 1024) {
      Message.error('file size cannot exceed 5MB');
      return false;
    }

    const dataField = config.data.dataField;

    let list: any[] = [];

    if (dataField) {
      list = [...handleFileStatus(steps, dataField, 'uploading', { uid: file.uid, name: file.name })];
      setSteps([...list]);
    } else {
      list = [...handleFileStatus(fileList, dataField, 'uploading', { uid: file.uid, name: file.name })];
      setFileList([...list]);
    }

    fileUpload(eFormType, { dataField, file, id: supplierId }).then((response: any) => {
      const { status, message, model } = response;
      if (status === 1) {
        Message.success('Successed');
        list = [...handleFileStatus(list, dataField, 'done', { ...model, uid: file.uid })];
        if (dataField) {
          setSteps([...list]);
        } else {
          setFileList([...list]);
        }
      } else {
        Message.error(message);
        list = [...handleFileStatus(list, dataField, 'error', { ...model, uid: file.uid })];
        if (dataField) {
          setSteps([...list]);
        } else {
          setFileList([...list]);
        }
      }
    });
  };

  const handleFileStatus = (list: any[], dataField: string, fileStatus: string, file: any) => {
    if (dataField) {
      list.map((item: any, index: number) => {
        if (index === current) {
          if (fileStatus === 'uploading') {
            item.keys[dataField].attachs.unshift({
              uid: file.uid,
              name: file.name,
              status: fileStatus,
              dataField,
            });
          } else {
            item.keys[dataField].attachs.map((child: any) => {
              if (child.uid === file.uid) {
                child.response = `{'status': ${fileStatus === 'done' ? 'success' : 'error'}}`;
                child.id = file.id;
                child.status = fileStatus;
              }
              return child;
            });
          }
        }
        return item;
      });
    } else {
      if (fileStatus === 'uploading') {
        list.unshift({
          uid: file.uid,
          name: file.name,
          status: fileStatus,
        });
      } else {
        list.map((item: any) => {
          if (item.uid === file.uid) {
            item.id = file.id;
            item.status = fileStatus;
            item.response = `{'status': ${fileStatus === 'done' ? 'success' : 'error'}}`;
          }
          return item;
        });
      }
    }
    return list;
  };

  const checkSupplierInfoStatus = (supplierVal: Array<object>) => {
    let allChildStatus: boolean[] = [];
    supplierVal.map((child: any) => {
      let childStatus = true;
      template.SupplierInfo.childrenTemplate[0].forEach((tem: any) => {
        const { parentField, parentValue, dataField } = tem;
        if (parentField) {
          // linkage field.
          if (child[parentField] === parentValue) {
            if (_.isEmpty(child[dataField])) {
              childStatus = false;
              return;
            }
          }
        } else {
          if (_.isEmpty(child[dataField])) {
            childStatus = false;
            return;
          }
        }
      });
      allChildStatus.push(childStatus);
      return child;
    });
    const hasValueChild = allChildStatus.filter((status: boolean) => !status);
    return hasValueChild.length === 0;
  };

  const handleChangeStepStatus = (values: any) => {
    const list = [...steps];
    list.map((item: any) => {
      const keys = _.keys(item.keys).filter((key: any) => {
        const parentField = item.keys[key].parentField;
        const groupName = item.keys[key].groupName;
        const parentValue = values[parentField];
        const required = item.keys[key].required;
        let isEqual = false;
        const isGroup = groupName && groupName === 'SupplierInfo' && key !== 'supplierInfo';
        if (isGroup) return false;
        if (_.isArray(parentValue)) {
          isEqual = _.isEqual(parentValue, item.keys[key].parentValue);
        } else {
          isEqual = parentValue === item.keys[key].parentValue;
        }
        return parentField ? isEqual && required : required;
      });
      const hasValues = keys.filter((key: string) => {
        // check supplierInfo group, every list finish, supplierInfo key is finish.
        let arrayKeyLength;
        if (_.isArray(values[key])) {
          if (key === 'supplierInfo') {
            arrayKeyLength = checkSupplierInfoStatus(values[key]) ? 1 : 0;
          } else { 
            arrayKeyLength = values[key].length;
          }
        }
        return _.isArray(values[key]) ? arrayKeyLength : values[key];
      });
      item.status = keys.length === hasValues.length ? 'finish' : 'wait';
      return item;
    });
    setSteps([...list]);
  };

  const handleSupplierStatus = (action: string) => {
    if (['approve', 'reject'].indexOf(action) !== -1) {
      setModalProps({
        title: 'Confirm',
        okText: action === 'reject' ? 'Reject' : 'Approve',
        type: action,
        dataField: 'comment',
      });
      setIsModalVisible(true);
    } else {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: `Are you sure to ${action} this supplier?`,
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          handleChangeSupplierStatus(action, { id: supplierId });
        },
      });
    }
  };

  const handleChangeSupplierStatus = (action: string, data: any) => {
    setIsLoading(true);
    changeSupplierStatus(eFormType, action, data).then((response: any) => {
      const { status, message } = response;
      if (status === 1) {
        Message.success('Successed');
        let progress, role1Status, role2Status;
        let role = '';
        if (['reopen', 'restore'].indexOf(action) !== -1) {
          progress = 0;
          role1Status = 'wait';
          role2Status = 'wait';
          role = action === 'reopen' ? 'editor' : 'delete';
        } else if (action === 'delete') {
          progress = -1;
          role1Status = 'wait';
          role2Status = 'wait';
          role = 'restore';
        } else if (action === 'reject') {
          progress = 4;

          if (state.supplier.current === 1) {
            role1Status = 'error';
            role2Status = 'wait';
          } else {
            role1Status = 'finish';
            role2Status = 'error';
          }
          role = '';
        } else {
          progress = state.supplier.current + 1;

          if (state.supplier.current === 1) {
            role1Status = 'finish';
            role2Status = 'wait';
          } else {
            role1Status = 'finish';
            role2Status = 'finish';
          }
          role = '';
        }
        dispatch({
          type: 'supplier',
          payload: {
            role1: {
              ...state.supplier.role1,
              status: role1Status,
            },
            role2: {
              ...state.supplier.role2,
              status: role2Status,
            },
            current: progress,
            action: role,
          },
        });

        if (['approve', 'reject'].indexOf(action) !== -1) {
          setIsModalVisible(false);
        }
      } else {
        Message.error(message);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleChangeSupplierInfo = (changedKey: string, changedValue: any) => {
    const keyArray = Object.keys(changedValue);
    const newData = [ ...form.getFieldValue(changedKey) ];
    if (keyArray.length > 1) {
      // formList remove or add, length change.
      keyArray.forEach((key) => {
        const index = Number(key);
        if (!changedValue[index]) {
          newData[index] = {};
          supplierTemplate.forEach((filed: any) => {
            const { dataField, defaultValue, parentField } = filed;
            newData[index][dataField] = parentField ? [] : defaultValue;
          });
        }
      });
    } else {
      // formList item field value change.
      const changedSecondKey = Number(keyArray[0]);
      const changedSecondValue = changedValue[changedSecondKey];
      if (_.isObject(changedSecondValue) && _.keys(changedSecondValue).length === 1) {
        const changedThirdKey = _.keys(changedSecondValue)[0];
        const changedThirdValue = _.values(changedSecondValue)[0];
        newData[changedSecondKey][changedThirdKey] = changedThirdValue;
        if (changedThirdKey === 'supplierType') {
          supplierTemplate.forEach((fieldTemplate: any) => {
            const { parentField, dataField } = fieldTemplate;
            if (parentField && parentField === changedThirdKey) {
              newData[changedSecondKey][dataField] = [];
            }
          });
        }

        if (changedThirdKey !== 'levelJustification') {
          let levelJustificationArr: string[] = [];
          const obj = newData[changedSecondKey];

          obj.supplierType && obj.supplierImpactLevel && _.keys(obj).forEach((key: any) => {
            if (!key.includes('supplierGroup') || obj[key].length === 0) return;

            const linkedParents = linkageDefaultValue[key].defaultVaules.filter((item: any) => obj[key].includes(item.value));
            let pvObj: any = {};

            linkedParents.forEach((item: any) => {
              for (let pv of item.parentValue) {
                if (pv.value === obj.supplierImpactLevel && pv.defaultValue) {
                  pvObj[item.value] = pv.defaultValue;
                  break;
                }
              }
            });

            obj[key].forEach((item: any) => {
              if (!pvObj[item]) return;
              levelJustificationArr = [...levelJustificationArr, pvObj[item]];
            });
          });
          newData[changedSecondKey].levelJustification = levelJustificationArr.join('\n');
        }
      }
    }
    const newFormValues = { ...formValues };
    newFormValues[changedKey] = [ ...newData ];
    form.setFieldsValue({ ...newFormValues });
    setFormValues({ ...newFormValues });
    setIsChange(true);
    return;
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    let keys: any = {};
    let values: any = {};
    const changedKey = _.keys(changedValues)[0];
    const changedValue = _.values(changedValues)[0];
    if (changedKey === 'supplierInfo') {
      handleChangeSupplierInfo(changedKey, changedValue);
      return;
    }

    if (_.keys(linkageDefaultValue).indexOf(changedKey) !== -1) {
      const linkageKey = linkageDefaultValue[changedKey].dataField;
      const linkedParents = linkageDefaultValue[changedKey].defaultVaules.filter((item: any) => item.value === changedValue);
      
      if (linkedParents.length) {
        linkedParents.forEach((item: any) => {
          const { parentField, parentValue } = item;
          if (parentField && parentValue) {
            if (formValues[parentField]) {
              const parent = parentValue.find((child: any) => child.value === formValues[parentField]);
              values[linkageKey] = parent ? parent.defaultValue : '';
            }
          } else {
            if (_.isArray(linkageKey)) {
              linkageKey.forEach((linkageChildrenKey) => values[linkageChildrenKey] = item.defaultValue);
            } else {
              values[linkageKey] = item.defaultValue;
            }
          }
        });
      } else {
        values[linkageKey] = '';
      }
    }

    _.values(template).forEach((item: any) => {
      if (_.isArray(item)) {
        item.forEach((child: any) => {
          if (child.parentField === changedKey && child.parentValue !== changedValue) {
            keys[child.dataField] = setDefaultValue(child.defaultValue, child.type);
          }
        });
      } else {
        if (item.parentField === changedKey && item.parentValue !== changedValue) {
          keys[item.dataField] = setDefaultValue(item.defaultValue, item.type);
        }
      }
    });

    if (_.keys(keys).length) {
      values = { ...values, ...keys };
    }

    form.setFieldsValue({ ...formValues, ...changedValues, ...values });
    setFormValues({ ...formValues, ...changedValues, ...values });
    setIsChange(true);
  };

  const handleStepChange = async (index: number) => {
    const next = index < steps.length ? index : steps.length - 1;
    if (isChange && state.supplier.action === 'editor') {
      handleFormSave(next);
    } else {
      setCurrent(next);
      window.scrollTo(0, 0);
    }
  };

  const handleFormSubmit = async () => {
    try {
      await form.validateFields();
      const step = steps.findIndex((item: any, index: number) => item.status !== 'finish' && index !== current);
      if (step !== -1) {
        if (isChange && state.supplier.action === 'editor') {
          saveSupplierDraft(eFormType, { ...formValues, id: supplierId }).then((response: any) => {
            const { status, message } = response;
            if (status === 1) {
              Message.success('Successed');
              handleChangeStepStatus(formValues);

              setCurrent(step);
              window.scrollTo(0, 0);
              form.validateFields();
              Message.error('This step is not finished!');
            } else {
              Message.error(message);
            }
            setIsLoading(false);
            setIsChange(false);
          }).catch(() => {
            setIsLoading(false);
          });
        } else {
          setCurrent(step);
          window.scrollTo(0, 0);
          form.validateFields();
          Message.error('This step is not finished!');
        }
      } else {
        validateSupplier(eFormType, { ...formValues, id: supplierId }).then(async (response: any) => {
          const { status, model, message } = response;
          if (status === 1) {
            Message.success('Successed');
            const list = [...steps];
            list.map((item: any) => {
              item.status = 'finish';
              return item;
            });
            setSteps([...list]);
            if (approverList.length) {
              getApproverList(approverList);
            } else {
              try {
                const res: any = await getAllReviewers(eFormType, { id: supplierId });
                if (res.status === 1) {
                  getApproverList(res.model);
                } else {
                  Message.error(res.message);
                }
              } catch {}
            }
          } else if (status === 5) {
            // list error message
            model.forEach((item: any) => {
              Message.error(`${item.enLabel} ${item.label} is required`);
            });
          } else {
            Message.error(message);
          }
        }).finally(() => {
          setIsLoading(false);
          setIsChange(false);
        });
      }
    } catch (error: any) {
      Message.error('This step is not finished!');
    }
  };

  const getApproverList = (data: any) => {
    const values: any = {};
    setApproverList([...data.map((item: any) => {
      if (item.roleName === 'LSO' || item.roleName === 'Quality function') {
        if (state.supplier.role1.current === 3) {
          item.resend = true;
          item.disabled = true;
        } else {
          item.resend = false;
          item.disabled = false;
        }
        values[item.roleName] = state.supplier.role1.id;
      } else if (item.roleName === 'QL' || item.roleName === 'PV function') {
        item.disabled = false;
        item.resend = false;
        values[item.roleName] = state.supplier.role2.id;
      } else {
        item.disabled = false;
        item.resend = false;
      }
      return item;
    })]);
    setModalProps({
      title: 'Approve',
      okText: 'Submit',
      type: 'assign',
    });
    setIsModalVisible(true);
    handleGetCaptcha();
    modalForm.setFieldsValue(values);
  };

  const handleFormSave = async (step: number) => {
    if (!formValues.supplierName) {
      Message.error('Name of Supplier is required!');
      setCurrent(0);
      window.scrollTo(0, 0);
      return;
    }
    try {
      await form.validateFields();
      saveDraft(formValues, true, step);
    } catch (error: any) {
      const { values, errorFields } = error;
      if (errorFields.filter((item: any) => item.errors[0] === 'this field is not valid').length === 0) {
        saveDraft(values, false, step);
      }
    }
  };

  const saveDraft = (values: any, validate: boolean, nextStep: number) => {
    const data = supplierId ? { ...formValues, id: supplierId } : { ...formValues };
    saveSupplierDraft(eFormType, data).then((response: any) => {
      const { status, model, message } = response;
      if (status === 1) {
        Message.success('Successed');
        if (!supplierId) {
          navigate(`/supplier?eFormType=${eFormType}&id=${model.id}`);
        }
        handleChangeStepStatus(formValues);
        setCurrent(nextStep);
        window.scrollTo(0, 0);
      } else {
        Message.error(message);
      }
      setIsLoading(false);
      setIsChange(false);
    }).catch(() => {
      setIsLoading(false);
    });
  };

  const handleShowComments = (name: string) => {
    setIsDrawerVisible(true);
    setCommentField(name);
  };

  const handleCloseDrawer = () => {
    setIsDrawerVisible(false);
    setCommentField('');
  };

  const handleAddComment = (dataField: string) => {
    setModalProps({
      title: 'Add comment',
      okText: 'Save',
      type: 'add',
      dataField,
    });
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    modalForm.resetFields();
  };

  const handleModalSubmit = (values: any) => {
    if (modalProps.type === 'add') {
      const dataField = _.keys(values)[0];
      const comment = _.values(values)[0];
      const data = {
        id: supplierId,
        dataField,
        comment,
      };
      saveComment(eFormType, data).then((response: any) => {
        const { status, message } = response;
        if (status !== 1) {
          Message.error(message);
        } else {
          Message.success('Successed');
          const list = [...steps];
          list.map((item: any, index: number) => {
            if (index === current) {
              item.keys[dataField].comments.push({
                comment,
                createTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                createUserName: state.user.name,
              });
            }
            return item;
          });
          setSteps([...list]);
          setIsModalVisible(false);
        }
      });
    } else if (modalProps.type === 'assign') {
      const reviewerUserIds: any[] = [];
      approverList.forEach((item: any) => {
        if (!item.disabled) {
          reviewerUserIds.push(values[item.roleName]);
        }
      });
      submitSupplier(eFormType, {
        id: supplierId,
        reviewerUserIds,
        captchaId,
        captchaValue: values.captchaValue,
      }).then((response: any) => {
        const { status, message } = response;
        if (status === 1) {
          Message.success('Successed');
          dispatch({
            type: 'supplier',
            payload: {
              ...state.supplier,
              current: 1,
              role1: {
                ...state.supplier.role1,
                status: reviewerUserIds.length === 1 ? 'finish' : 'wait',
              },
              role2: {
                ...state.supplier.role2,
                status: 'wait',
              },
              action: '',
            },
          });
        } else {
          Message.error(message);
          handleGetCaptcha();
        }
        setIsModalVisible(false);
      });
    } else {
      handleChangeSupplierStatus(modalProps.type, { id: supplierId, comment: values.comment });
    }
  };

  const handleResend = (e: any, roleName: string) => {
    const value: any = {};
    value[roleName] = e.target.checked;
    setResend({ ...resend, ...value });
    setApproverList([...approverList.map((item: any) => {
      if (item.roleName === roleName) {
        item.disabled = !e.target.checked;
      }
      return item;
    })]);
  };

  const handleGetCaptcha = () => {
    getCaptcha().then((response: any) => {
      const { id, data, mimeType } = response;
      setCaptchaId(id);
      setCodeBase64Img(`data:${mimeType};base64, ${data}`);
    }).catch(() => {
      setCaptchaId('');
      setCodeBase64Img('');
    });
  };

  const isItemDisabled = () => {
    if (current === 4 && eFormType === 'TV-eFRM-16853') {
      // QL: under edit status(draft in process) can editable.
      if (state.user.role === 3 && state.supplier.role1.status === 'wait' && state.supplier.role2.status === 'wait') return false;
    }
    return state.supplier.action !== 'editor';
  };

  const isDisabled =  isItemDisabled();

  return (
    <div className={`${styles.wrapper} supplier_form`}>
      <div className={styles.content}>
        <Timeline>
          {commentList.map((item: any, index: number) => <Timeline.Item key={index}>{item.comment} - {item.createUserName}, {item.createTime}</Timeline.Item>)}
        </Timeline>
        {steps.length > 0 && <h3>{steps[current]?.name} {steps[current]?.description}</h3>}
        {steps.length > 0 && <p>{steps[current]?.tips}</p>}
        {_.values(template).length > 0 && (
          <Form
            className={styles.form}
            form={form}
            size='large'
            colon={false}
            layout='vertical'
            scrollToFirstError={true}
            onValuesChange={handleValuesChange}
          >
            {steps.length > 0 && steps[current].showKeys.length === 0 && <p>This step is not needed. Please ignore this.</p>}
            {_.values(template).map((item: any, index: number) => (
              _.isArray(item) && item.filter((child: any) => child.step === current && !isFieldHidden(child)).length > 0 ? (
                <div key={index} className={styles.group}>
                  {item.map((child: any, num: number) => (
                    child.step === current &&
                    <FormItem
                      {...child}
                      disabled={isDisabled}
                      isHidden={isFieldHidden(child)}
                      isAddComment={state.supplier.action === 'review' || (state.supplier.action === 'editor' && supplierId)}
                      onAddComment={() => handleAddComment(child.dataField)}
                      onShowComments={() => handleShowComments(child.dataField)}
                      isUpload={state.supplier.action === 'editor' && supplierId}
                      onFileUpload={handleFileUpload}
                      onFileRemove={handleFileRemove}
                      onFileDownload={handleFileDownload}
                      comments={steps[current]?.keys[child.dataField]?.comments || []}
                      attachs={steps[current]?.keys[child.dataField]?.attachs || []}
                      key={index + '_' + num}
                    />
                  ))}
                </div>
              ) : (
                item.step === current &&
                <FormItem
                  {...item}
                  disabled={isDisabled}
                  isHidden={isFieldHidden(item)}
                  isAddComment={state.supplier.action === 'review' || (state.supplier.action === 'editor' && supplierId)}
                  onAddComment={() => handleAddComment(item.dataField)}
                  onShowComments={() => handleShowComments(item.dataField)}
                  isUpload={state.supplier.action === 'editor' && supplierId}
                  onFileUpload={handleFileUpload}
                  onFileRemove={handleFileRemove}
                  onFileDownload={handleFileDownload}
                  comments={steps[current]?.keys[item.dataField]?.comments || []}
                  attachs={steps[current]?.keys[item.dataField]?.attachs || []}
                  key={index}
                />
              )
            ))}
            {state.supplier.action === 'restore' && (
              <div className={styles.footer}>
                <Button type='primary' size='large' onClick={() => handleSupplierStatus('restore')} loading={isLoading}>Restore</Button>
              </div>
            )}
            {/* supplier in any status, can delete */}
            {state.supplier.action === 'delete' && (
              <div className={styles.footer}>
                <Button danger type='primary' size='large' onClick={() => handleSupplierStatus('delete')} loading={isLoading}>Delete</Button>
              </div>
            )}
            {/* editor */}
            {/* create supplier of supplier in draft status can edit  */}
            {state.supplier.action === 'editor' && (
              <div className={styles.footer}>
                <div>
                  <Button size='large' className={styles.submit} loading={isLoading} onClick={() => handleFormSave(current)}>Save</Button>
                  <Button type='primary' size='large' onClick={() => handleStepChange(current + 1)} loading={isLoading} disabled={current === steps.length - 1}>Next</Button>
                </div>
                { supplierId &&
                  <Button type='primary' size='large' loading={isLoading} onClick={handleFormSubmit}>Submit</Button>
                }
              </div>
            )}
            {/* supplier in completed or delete status can reopen to edit */}
            {state.supplier.action === 'reopen' && (
              <div className={styles.footer}>
                <Button type='primary' size='large' onClick={() => handleSupplierStatus('reopen')} loading={isLoading}>Reopen</Button>
              </div>
            )}
            {/* OL or Quality function */}
            {state.supplier.action === 'review' && (
              <div className={styles.footer}>
                <Button type='primary' size='large' onClick={() => handleSupplierStatus('approve')} loading={isLoading}>Approve</Button>
                <Button danger type='primary' size='large' onClick={() => handleSupplierStatus('reject')} loading={isLoading}>Reject</Button>
              </div>
            )}
            {/* OL edit in draft function */}
            {state.supplier.action === 'ql_edit' && !isDisabled && (
              <div className={styles.footer}>
                <Button size='large' className={styles.submit} loading={isLoading} onClick={() => handleFormSave(current)}>Save</Button>
              </div>
            )}
          </Form>
        )}
      </div>
      <div className={styles.sider_right}>
        <Steps
          current={current}
          size='small'
          direction='vertical'
          onChange={handleStepChange}
        >
          {steps.map((item: any, index: number) => (
            <Step
              status={index === current ? 'process' : item.status}
              key={index}
              title={(_.values(item.keys).filter((value: any) => !isFieldHidden(value) && _.isArray(value.comments) && value.comments.length).length > 0) ? <p>{item.name} <ExclamationCircleOutlined style={{ color: 'red' }} /></p> : item.name}
              description={item.description}
            />
          ))}
        </Steps>
        {state.supplier.action === 'editor' && supplierId && <p>Upload other supporting documents if needed:</p>}
        {fileList.length > 0 && state.supplier.action !== 'editor' && <p>Documents:</p>}
        <Upload
          accept='.jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf'
          customRequest={handleFileUpload}
          data={{ dataField: '' }}
          fileList={fileList}
          onRemove={handleFileRemove}
          onDownload={handleFileDownload}
          showUploadList={{
            showDownloadIcon: true,
            downloadIcon: <DownloadOutlined />,
            showRemoveIcon: true,
          }}
        >
          {state.supplier.action === 'editor' && supplierId && <Button size='small' icon={<UploadOutlined />}>Click to Upload</Button>}
        </Upload>
        {state.supplier.current === 3 && (<Button
          className={styles.export}
          type='primary'
          icon={<DownloadOutlined />}
          onClick={handleExport}
        >
          Export
        </Button>)}
      </div>
      <Modal
        title={modalProps.title}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key='cancle' onClick={handleModalCancel}>
            Cancle
          </Button>,
          <Button form='modal' key='submit' type='primary' htmlType='submit'>
            {modalProps.okText}
          </Button>,
        ]}
      >
        <Form id='modal' form={modalForm} onFinish={handleModalSubmit} preserve={false}>
          {modalProps.type === 'add' && (
            <Item
              name={modalProps.dataField}
              rules={[{ required: true, message: '' }]}
            >
              <Input.TextArea placeholder='please input...' />
            </Item>
          )}
          {['reject', 'approve'].indexOf(modalProps.type) !== -1 && (
            <div>
              <p>Are you sure to {modalProps.type} this supplier?</p>
              <Item name='comment'>
                <Input.TextArea placeholder='please add comment...' />
              </Item>
            </div>
          )}
          {modalProps.type === 'assign' && <div>
            {approverList.map((item: any, index: number) => {
              return (
                <div className={styles.modal} key={index}>
                  <Item
                    className={styles.item}
                    label={item.roleName}
                    name={item.roleName}
                    rules={[
                      {
                        required: true,
                        message: `Please select ${item.roleName}`,
                      },
                    ]}>
                    <Select style={{ width: 180 }} placeholder='Please select' disabled={item.disabled}>
                      {item.users.map((option: any) => <Select.Option value={option.userId} key={option.userId}>{option.userName}</Select.Option>)}
                    </Select>
                  </Item>
                  {item.resend && <Checkbox checked={resend[item.roleName]} onChange={(e) => handleResend(e, item.roleName)}>resend</Checkbox>}
                </div>
              );
            })}
            <div className={styles.captcha}>
              <Item
                style={{ width: '60%' }}
                name='captchaValue'
                rules={[
                  {
                    required: true,
                    message: 'Please input verification code!',
                  },
                ]}
              >
                <Input
                  prefix={<SafetyOutlined className={styles.icon} />}
                  size='large'
                  placeholder='Code'
                  autoComplete='off'
                />
              </Item>
              <div className={styles.code} onClick={handleGetCaptcha}>
                {codeBase64Img ? <img style={{ display: 'block' }} src={codeBase64Img} alt='验证码' /> : '点击刷新'}
              </div>
            </div>
          </div>}
        </Form>
      </Modal>
      <Drawer
        title='Comment List'
        placement='right'
        width={378}
        closable={true}
        onClose={handleCloseDrawer}
        visible={isDrawerVisible}
      >
        <Timeline>
          {commentField && steps[current].keys[commentField].comments.map((item: any, index: number) => <Timeline.Item key={index}>{item.comment}<br/>- {item.createUserName}, {item.createTime}</Timeline.Item>)}
        </Timeline>
      </Drawer>
    </div>
  );
};

export default Supplier;
