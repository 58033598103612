import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Radio,
  Checkbox,
  Upload,
  Switch,
  Tag,
} from 'antd';
import {
  DownloadOutlined,
  PlusOutlined,
  // EditOutlined,
  CaretRightOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import styles from './../scss/modules/item.module.scss';
import { FormList } from './FormList';

const FormItem = (props: any) => {
  const patterns: any = {
    'mb-phone': '\\d$',
    'tel-phone': '0\\d{2,3}-\\d{7,8}(-\\d{1,6})?',
    email: '^\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$',
    number: '\\d',
  };

  const { Item } = Form;
  const { disabled, maxLength, options } = props;
  const config = {
    label: props.enLabel + ' ' + props.label,
    tooltip: props.tips ? () => <div>{props.tips.split('\r\n').map((tip: string, index: number) => <span key={index}>{tip}<br /></span>)}</div> : props.tips,
    name: props.dataField,
    rules: ['tel-phone', 'input', 'mb-phone', 'email', 'number'].indexOf(props.type) === -1 ? [{
      required: props.required && !props.isHidden,
      message: 'this field is required',
    }] : [{
      required: props.required && !props.isHidden,
      message: 'this field is required',
    }, {
      pattern: new RegExp(patterns[props.type]),
      message: 'this field is not valid',
    }],
  };

  const listProps = { ...config, childrenTemplate: props.childrenTemplate, disabled: props.disabled };

  return (
    <div className={`${props.type === 'group' ? styles.group : styles.item} ${props.isHidden ? styles.hidden : styles.visible}`}>
      {['tel-phone', 'input', 'mb-phone', 'email', 'number'].indexOf(props.type) !== -1 && (disabled ? <Item {...config}><Input.TextArea autoSize disabled={disabled} placeholder='please input...' /></Item> : <Item {...config}><Input disabled={disabled} maxLength={maxLength} allowClear={true} placeholder='please input...' autoComplete='off' /></Item>)}
      {props.type === 'textarea' && <Item {...config}><Input.TextArea autoSize disabled={disabled} maxLength={maxLength} allowClear={true} showCount={!disabled} placeholder='please input...' /></Item>}
      {props.type === 'date' && <Item {...config}><DatePicker disabled={disabled} /></Item>}
      {props.type === 'select' && <Item {...config}><Select disabled={disabled} options={options} allowClear={true} placeholder='please select' /></Item>}
      {props.type === 'multiple-select' && <Item {...config}><Select disabled={disabled} mode='multiple' options={options} allowClear={true} placeholder='please select' /></Item>}
      {props.type === 'radio' && <Item {...config}><Radio.Group disabled={disabled} options={options} /></Item>}
      {props.type === 'checkbox' && <Item {...config}><Checkbox.Group disabled={disabled} options={options} /></Item>}
      {props.type === 'file' && <Item {...config}><Upload disabled={disabled}><Button icon={<UploadOutlined />}>Upload</Button></Upload></Item>}
      {props.type === 'switch' && <Item {...config} valuePropName='checked'><Switch disabled={disabled} /></Item>}
      {props?.type === 'group' && <Item {...config}><FormList {...listProps} /></Item>}
      <div className={styles.comment}>
        {_.isArray(props.comments) && props.comments.length > 0 && (
          <Tag className={styles.tag} color='red' onClick={props.onShowComments}>
            <CaretRightOutlined /> {props.comments[props.comments.length - 1].comment}
          </Tag>
        )}
        {props.isAddComment && <Tag onClick={props.onAddComment} className={styles.tag}><PlusOutlined /> add comment</Tag>}
      </div>
      {props.needAttach && (
        <div className={styles.attach}>
          <Upload
            accept='.jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf'
            customRequest={props.onFileUpload}
            data={{ dataField: props.dataField }}
            fileList={props.attachs}
            onRemove={props.onFileRemove}
            onDownload={props.onFileDownload}
            showUploadList={{
              showDownloadIcon: true,
              downloadIcon: <DownloadOutlined />,
              showRemoveIcon: true,
            }}
          >
            {props.isUpload && <Button size='small' icon={<UploadOutlined />}>Upload</Button>}
          </Upload>
        </div>
      )}
    </div>
  );
};

export default FormItem;
