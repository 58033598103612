import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Form,
  Select,
  Input,
  Checkbox,
  Button,
  Table,
  Modal,
  message as Message,
  Space,
  Switch,
} from 'antd';
import styles from './../scss/modules/list.module.scss';
import { ColumnsType } from 'antd/es/table';
import { AppContext } from '../common';
import { getAccountList, createOrUpdateAccount, generateAndSendRegisterLink } from '../api';

const Account = () => {
  const columns: ColumnsType<any> = [
    {
      title: 'User Name',
      dataIndex: 'userName',
    },
    {
      title: 'Account',
      dataIndex: 'account',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: role => ['admin', 'requestor', 'QL', 'LSO', 'Quality function', 'PV function'][role - 1],
    },
    {
      title: 'Enable',
      dataIndex: 'ifEnable',
      render: ifEnable => ifEnable ? '启用' : '禁用',
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <Button type='link' onClick={() => handleEditAccount(record)}>Edit</Button>
        </Space>
      ),
    },
  ];
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const { Item } = Form;
  const [searchForm] = Form.useForm();
  const [accountForm] = Form.useForm();
  const [queryModel, setQueryModel] = useState<any>({
    Keyword: '',
    RoleId: -1,
    IfEnable: -1,
    pageCount: 10,
    pageIndex: 1,
  });
  const [loading, setLoading] = useState(true);
  const [formList, setFormList] = useState<any>({
    entityTotalCount: 0,
    entities: [],
  });
  const [modalProps, setModalProps] = useState<any>({
    title: '',
    type: '',
    visible: false,
  });

  useEffect(() => {
    if (state.user.role !== 1) {
      navigate('/403');
      return;
    }
    if (state.isAuth) {
      dispatch({ type: 'menu', payload: { menuKey: '', pageTitle: 'Account' } });
      setLoading(true);
      getAccountList({
        pageIndex: queryModel.pageIndex,
        pageCount: queryModel.pageCount,
        'QueryModel.Keyword': queryModel.Keyword,
        'QueryModel.RoleId': queryModel.RoleId,
        'QueryModel.IfEnable': queryModel.IfEnable,
      }).then((response: any) => {
        const { model } = response;
        setFormList({ ...model });
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [queryModel, state.isAuth]);

  const handleSearch = (values: any) => {
    setQueryModel({
      ...queryModel,
      Keyword: values.Keyword,
      RoleId: values.RoleId !== undefined ? values.RoleId : -1,
      IfEnable: values.IfEnable !== undefined ? values.IfEnable : -1,
      pageIndex: 1,
    });
  };

  const handleReset = () => {
    searchForm.resetFields();
    setQueryModel({
      Keyword: '',
      RoleId: -1,
      IfEnable: -1,
      pageIndex: 1,
      pageCount: 10,
    });
  };

  const handleTableChange = (pagination: any) => {
    setQueryModel({
      ...queryModel,
      pageIndex: pagination.current,
      pageCount: pagination.pageSize,
    });
  };

  const handleEditAccount = (values: any) => {
    const { userName, account, ifEnable, role, accountId, remark, email, formIds, activated } = values;
    accountForm.setFieldsValue({
      userName,
      account,
      email,
      roleId: role,
      ifEnable: ifEnable ? 1 : 0,
      remark,
      formIds,
    });
    setModalProps({
      title: 'Edit Account',
      visible: true,
      type: 'edit',
      accountId,
      activated,
    });
  };

  const handleAddAccount = () => {
    accountForm.resetFields();
    setModalProps({
      title: 'Add Account',
      visible: true,
      type: 'add',
    });
  };
  
  const handleUpdateAccount = (values: any) => {
    console.log(values);
    const data = { ...values, ifEnable: values.ifEnable ? true : false };
    if (modalProps.type === 'edit') {
      data.userId = modalProps.accountId;
    }
    createOrUpdateAccount(data).then((response: any) => {
      const { status, message } = response;
      if (status === 1) {
        Message.success('Successed');
        setQueryModel({
          ...queryModel,
          pageIndex: 1,
        });
        setModalProps({
          title: '',
          visible: false,
          type: '',
        });
      } else {
        Message.error(message);
      }
    });
  };

  const handleModalCancel = () => {
    setModalProps({
      title: '',
      visible: false,
      type: '',
    });
  };

  const handleResendRegisterMail = () => {
    setLoading(true);
    generateAndSendRegisterLink(modalProps.accountId).then((response: any) => {
      setLoading(false);
      const { status, message } = response;
      if (status === 1) {
        Message.success('Successed');
        setQueryModel({
          ...queryModel,
          pageIndex: 1,
        });
        setModalProps({
          title: '',
          visible: false,
          type: '',
        });
      } else {
        Message.error(message);
      }
    });
  };
  
  return (
    <div className='List'>
      <div className={styles.wrapper}>
        <Form
          form={searchForm}
          name='listSearch'
          className={styles.search}
          onFinish={handleSearch}
        >
          <div className={styles.item_group}>
            <Item
              name='Keyword'
              label='Keyword'
              className={styles.item}
            >
              <Input placeholder='please input' autoComplete='off' />
            </Item>
            <Item
              name='RoleId'
              label='Role'
              className={styles.item}
            >
              <Select
                placeholder='please select'
                options={[{
                  label: 'All',
                  value: -1,
                }, {
                  label: 'admin',
                  value: 1,
                }, {
                  label: 'requestor',
                  value: 2,
                }, {
                  label: 'QL',
                  value: 3,
                }, {
                  label: 'LSO',
                  value: 4,
                }, {
                  label: 'Quality function',
                  value: 5,
                }, {
                  label: 'PV function',
                  value: 6,
                }]}
              />
            </Item>
            <Item
              name='IfEnable'
              label='Enable'
              className={styles.item}
            >
              <Select
                placeholder='please select'
                options={[{
                  label: 'All',
                  value: -1,
                }, {
                  label: '禁用',
                  value: 0,
                }, {
                  label: '启用',
                  value: 1,
                }]}
              />
            </Item>
          </div>
          <div className={styles.btn_group}>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
            <Button
              style={{ marginLeft: '8px' }}
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </Form>
        <Button style={{ marginTop: '20px' }} onClick={handleAddAccount}>Add new account</Button>
        <Table
          className={styles.table}
          loading={loading}
          columns={columns}
          rowKey={'accountId'}
          dataSource={formList.entities}
          pagination={{
            pageSize: queryModel.pageCount,
            current: queryModel.pageIndex,
            total: formList.entityTotalCount,
            hideOnSinglePage: true,
          }}
          onChange={handleTableChange}
        />
        <Modal
          title={modalProps.title}
          visible={modalProps.visible}
          onCancel={handleModalCancel}
          footer={[
            <Button key='cancle' onClick={handleModalCancel}>
              Cancle
            </Button>,
            <Button form='account' key='submit' type='primary' htmlType='submit'>
              Save
            </Button>,
          ]}
        >
          {modalProps.type !== 'password' && (
            <Form id='account' form={accountForm} onFinish={handleUpdateAccount}>
              <Item
                name='userName'
                label='Username'
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}
              >
                <Input placeholder='please input' autoComplete='off' />
              </Item>
              <Item
                name='account'
                label='Account'
                rules={[
                  {
                    required: true,
                    message: 'Please input your account!',
                  },
                ]}
              >
                <Input disabled={modalProps.type !== 'add'}  placeholder='please input' autoComplete='off' />
              </Item>
              {modalProps.type === 'add' && (
                <Item
                  name='password'
                  label='Password'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      pattern: new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{10,20}'),
                      message: 'Password must contain uppercase letters, lowercase letters, numbers, special symbols, and the length is 10-20',
                    },
                  ]}
                >
                  <Input.Password placeholder='please input' />
                </Item>
              )}
              {modalProps.type === 'add' && (
                <Item
                  name='sendRegisterMail'
                  label='Send Register Mail To User'
                >
                  <Switch />
                </Item>
              )}
              <Item
                name='email'
                label='Email'
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                  {
                    pattern: new RegExp('^\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$'),
                    message: 'email is not valid',
                  },
                ]}
              >
                <Input placeholder='please input' autoComplete='off' />
              </Item>
              <Item
                name='roleId'
                label='Role'
              >
                <Select
                  placeholder='please select'
                  options={[{
                    label: 'admin',
                    value: 1,
                  }, {
                    label: 'requestor',
                    value: 2,
                  }, {
                    label: 'QL',
                    value: 3,
                  }, {
                    label: 'LSO',
                    value: 4,
                  }, {
                    label: 'Quality function',
                    value: 5,
                  }, {
                    label: 'PV function',
                    value: 6,
                  }]}
                />
              </Item>
              <Item
                name='ifEnable'
                label='Enable'
              >
                <Select
                  placeholder='please select'
                  options={[{
                    label: '禁用',
                    value: 0,
                  }, {
                    label: '启用',
                    value: 1,
                  }]}
                />
              </Item>
              <Item
                name='formIds'
                label='Form Type'
              >
                <Checkbox.Group>
                  <Checkbox value='1'>TV-eFRM-16853</Checkbox>
                  <Checkbox value='2'>TV-eFRM-16856</Checkbox>
                </Checkbox.Group>
              </Item>
              <Item
                name='remark'
                label='Remark'
              >
                <Input placeholder='please input' autoComplete='off' />
              </Item>
              {modalProps.type !== 'add' &&
              <Button key='resendmail' loading={loading} onClick={handleResendRegisterMail}>
                Send Reset Password and Security Questions e-mail To User
              </Button>}
            </Form>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Account;
