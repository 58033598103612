import { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  message as Message,
} from 'antd';
import {
  forgetPassword,
  getCaptcha,
  getUserSecurityQuestions,
} from './../api';
import styles from './../scss/modules/login.module.scss';

const ForgetPassword = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [codeBase64Img, setCodeBase64Img] = useState('');
  const [forgetPasswordForm] = Form.useForm();
  const [captchaId, setCaptchaId] = useState('');
  const [userSecurityQuestion, setUserSecurityQuestion] = useState({ userId: 0, question1Id: 0, question1:'', question2Id: 0, question2: '', question3Id: 0, question3: '' });

  useEffect(() => {
    forgetPasswordForm.resetFields();
    handleGetCaptcha();
  }, []);
  
  // Get captcha
  const handleGetCaptcha = () => {
    setLoading(true);
    getCaptcha().then((response: any) => {
      const { id, data, mimeType } = response;
      setCaptchaId(id);
      setCodeBase64Img(`data:${mimeType};base64, ${data}`);
      setLoading(false);
    }).catch(() => {
      setCaptchaId('');
      setCodeBase64Img('');
    });
  };
  
  const handleForgetPassword = (values: any) => {    
    setLoading(true);
    forgetPassword({ ...values, captchaId, question1Id:userSecurityQuestion.question1Id, question2Id: userSecurityQuestion.question2Id, question3Id: userSecurityQuestion.question3Id }).then((response: any) => {
      const { status, message } = response;
      if (status === 1) {
        Message.success('Password has been changed, please log in with new password.');
        setLoading(false);
        props.closeModal();
      } else {
        Message.error(message);
        setLoading(false);
        handleGetCaptcha();
      }
    }).catch(() => {
      setLoading(false);
      handleGetCaptcha();
    });
  };
  
  const handleGetUserSecurityQuestions = () => {
    setLoading(true);
    const loginId = forgetPasswordForm.getFieldValue('loginId');
    getUserSecurityQuestions({ loginId }).then((response: any) => {
      setLoading(false);
      const { status, message } = response;
      if (status === 1) {
        setUserSecurityQuestion(response.model);
      } else {
        Message.error(message);
      }
    }).catch(() => {
      setLoading(false);
    });
  };

  return (
    <Modal
      title='Forget password'
      visible={props.visible}
      onCancel={props.closeModal}
      maskClosable={false}
      footer={[
        <Button key='cancle' onClick={props.closeModal}>
          Cancle
        </Button>,
        userSecurityQuestion.userId > 0 && <Button form='forgetpassword' key='submit' type='primary' htmlType='submit' loading={loading}>
          Submit
        </Button>,
        userSecurityQuestion.userId === 0 && <Button key='getUserSecurityQuestions' type='primary' loading={loading} onClick={handleGetUserSecurityQuestions}>
          Next
        </Button>,
      ]}
      >
        <Form id='forgetpassword' form={forgetPasswordForm} onFinish={handleForgetPassword}>
          <Form.Item
            name='loginId'
            label='Account Name'
            rules={[
              {
                required: true,
                message: 'Please input your account name!',
              },
            ]}
          >
            <Input placeholder='please input' />
          </Form.Item>
          { userSecurityQuestion.userId > 0 && 
            <div><Form.Item
              name='question1'
              label='Security Question 1'
            >
              {userSecurityQuestion.question1}
            </Form.Item>
            <Form.Item
              name='answer1'
              label='Security Answer 1'
              rules={[
                {
                  required: true,
                  message: 'Please input your answer!',
                },
              ]}
            >
              <Input placeholder='please input' />
            </Form.Item>
            <Form.Item
              name='question2'
              label='Security Question 2'
            >
              {userSecurityQuestion.question2}
            </Form.Item>
            <Form.Item
              name='answer2'
              label='Security Answer 2'
              rules={[
                {
                  required: true,
                  message: 'Please input your answer!',
                },
              ]}
            >
              <Input placeholder='please input' />
            </Form.Item>
            <Form.Item
              name='question3'
              label='Security Question 3'
            >
              {userSecurityQuestion.question3}
            </Form.Item>
            <Form.Item
              name='answer3'
              label='Security Answer 3'
              rules={[
                {
                  required: true,
                  message: 'Please input your answer!',
                },
              ]}
            >
              <Input placeholder='please input' />
            </Form.Item>
            <Form.Item
              name='newPassword'
              label='New password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  pattern: new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{10,20}'),
                  message: '密码需包含大写字母、小写字母、数字、特殊符号, 长度为10-20',
                },
              ]}
            >
              <Input.Password placeholder='please input' />
            </Form.Item>
            <Form.Item
              name='confirmPwd'
              label='Confirm password'
              dependencies={['newPwd']}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
      
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder='please input' />
            </Form.Item>
            <div className={styles.captcha}>
              <Form.Item
                style={{ width: '60%' }}
                name='captchaValue'
                label='Verification Code'
                rules={[
                  {
                    required: true,
                    message: 'Please input verification code!',
                  },
                ]}
              >
                <Input placeholder='please input' autoComplete='off' />
              </Form.Item>
              <div className={styles.code} onClick={handleGetCaptcha}>
                  {codeBase64Img ? <img style={{ display: 'block' }} src={codeBase64Img} alt='验证码' /> : '点击刷新'}
              </div>
            </div>
          </div>
          }
        </Form>
    </Modal>
  );
};

export default ForgetPassword;
