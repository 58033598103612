import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Result, Button } from 'antd';
import { AppContext } from '../common';

const resultStatus: any = {
  403: {
    title: '403',
    subTitle: 'Sorry, you are not authorized to access this page.',
  },
  500: {
    title: '500',
    subTitle: 'Sorry, something went wrong.',
  },
  404: {
    title: '404',
    subTitle: 'Sorry, the page you visited does not exist.',
  },
};

const Other = () => {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const { result } = useParams();
  const [info, setInfo] = useState<any>({});
  const [status, setStatus] = useState<'success' | 'error' | 'info' | 'warning' | 404 | 403 | 500>(404);

  useEffect(() => {
    dispatch({ type: 'menu', payload: { menuKey: '', pageTitle: '' } });
    if (result === '403') {
      setInfo(resultStatus[403]);
      setStatus(403);
    } else if (result === '500') {
      setInfo(resultStatus[500]);
      setStatus(500);
    } else {
      setInfo(resultStatus[404]);
      setStatus(404);
    }
  }, [result]);

  const handleBackHome = () => {
    navigate('/');
  };

  return (
    <Result
      status={status}
      title={info.title}
      subTitle={info.subTitle}
      extra={<Button type='primary' onClick={handleBackHome}>Back Home</Button>}
    />
  );
};

export default Other;
