import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  Form,
  Input,
  Select,
  Button,
  Table,
  Badge,
  Modal,
  message as Message,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import jsFileDownload from 'js-file-download';
import styles from './../scss/modules/list.module.scss';
import { ColumnsType } from 'antd/es/table';
import { getFormDataList, deleteFormData, exportASL } from './../api';
import { AppContext, FormList, ListQueryModel, ListTableItem } from '../common';

const List = () => {
  const { state, dispatch } = useContext(AppContext);
  const { Grid } = Card;
  const { Item } = Form;
  const [form] = Form.useForm();
  const [formList, setFormList] = useState<FormList>({
    total: 0,
    entityTotalCount: 0,
    toAudit: 0,
    inTheReview: 0,
    completed: 0,
    deleted: 0,
    entities: [],
  });
  const [queryModel, setQueryModel] = useState<ListQueryModel>({
    Supplier: '',
    EformType: '',
    SortBy: 'updateTime',
    AscOrDesc: 'desc',
    StatusList: [],
    pageCount: 10,
    pageIndex: 1,
  });
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const columns: ColumnsType<ListTableItem> = [
    {
      title: 'Eform type',
      dataIndex: 'eformType',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      render: (text, item) => <Link to={`/supplier?eFormType=${item.eformType}&id=${item.id}`}>{text || 'Supplier'}</Link>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: state.user.role === 1 ? [
        {
          text: 'Draft in process',
          value: 0,
        },
        {
          text: 'Under review',
          value: 1,
        },
        {
          text: 'Completed',
          value: 3,
        },
        {
          text: 'Rejected',
          value: 4,
        },
        {
          text: 'Deleted',
          value: -1,
        },
      ] : [
        {
          text: 'Draft in process',
          value: 0,
        },
        {
          text: 'Under review',
          value: 1,
        },
        {
          text: 'Completed',
          value: 3,
        },
        {
          text: 'Rejected',
          value: 4,
        },
      ],
      filteredValue: queryModel.StatusList,
      render: item => {
        if (parseInt(item) === 0) {
          return <Badge status='default' text='Draft in process' />;
        } else if (parseInt(item) === 1 || parseInt(item) === 2) {
          return <Badge status='processing' text='Under review' />;
        } else if (parseInt(item) === 3) {
          return <Badge status='success' text='Completed' />;
        } else if (parseInt(item) === 4) {
          return <Badge status='error' text='Rejected' />;
        } else if (parseInt(item) === -1) {
          return <Badge status='error' text='Deleted' />;
        } else {
          return <Badge status='warning' text='Unkown' />;
        }
      },
    },
    {
      title: 'Update time',
      dataIndex: 'updateTime',
      sorter: true,
    },
    {
      title: 'Requestor',
      dataIndex: 'updateUserName',
    },
  ];

  useEffect(() => {
    if (state.isAuth) {
      // Set page title in header, change menu active
      dispatch({ type: 'menu', payload: { menuKey: 'list', pageTitle: 'List' } });

      setLoading(true);

      // Get form data
      getFormDataList({
        pageIndex: queryModel.pageIndex,
        pageCount: queryModel.pageCount,
        'QueryModel.Supplier': queryModel.Supplier,
        'QueryModel.EformType': queryModel.EformType,
        'SortModel.SortBy': queryModel.SortBy,
        'SortModel.AscOrDesc': queryModel.AscOrDesc,
        'QueryModel.StatusList': queryModel.StatusList.includes(1) ? queryModel.StatusList.concat(2).join() : queryModel.StatusList.join(),
      }).then((response: any) => {
        const { model } = response;
        setFormList({ ...model });
        setSelectedRowKeys([]);
        setLoading(false);
      }).catch(() => {
        setSelectedRowKeys([]);
        setLoading(false);
      });
    } else {
      setSelectedRowKeys([]);
      setLoading(false);
    }
  }, [queryModel, state.isAuth]);

  /**
   * Filter data by search
   * @param {ListQueryModel} values - Supplier and EformType
   */
  const handleSearch = (values: ListQueryModel) => {
    setQueryModel({
      ...queryModel,
      ...values,
      pageIndex: 1,
    });
  };

  // Reset search result
  const handleReset = () => {
    form.resetFields();
    setQueryModel({
      ...queryModel,
      Supplier: '',
      EformType: '',
      pageIndex: 1,
    });
  };

  /**
   * Filter data by status change
   * @param {string[]} status - status array
   */
  const handleStatusFilter = (status: number[] = []) => {
    form.resetFields();
    setQueryModel({
      ...queryModel,
      Supplier: '',
      EformType: '',
      StatusList: status,
      pageIndex: 1,
    });
  };

  /**
   * Handle table row selected
   * @param {any} keys - selected key in table row 
   * @param {any} records - selected table row data
   */
  const handleSelectChange = (keys: any, records: any) => {
    setSelectedRowKeys(keys.filter((item: string, index: number) => records[index].status !== -1));
  };

  /**
   * Filter data by pagination, filters and soter change
   * @param pagination 
   * @param filters 
   * @param sorter 
   */
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setQueryModel({
      ...queryModel,
      pageIndex: pagination.current,
      pageCount: pagination.pageSize,
      StatusList: filters.status ? filters.status : [],
      AscOrDesc: sorter && sorter.order === 'ascend' ? 'asc' : 'desc',
    });
  };

  // Delete data
  const handleDelete = () => {
    const data: any[] = [];
    selectedRowKeys.forEach((key: string) => {
      const record = formList.entities.find((item: any) => `${item.eformType}_${item.id}` === key);
      if (record) {
        data.push({
          formId: record.eformTypeId,
          id: record.id,
        });
      }
    });

    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to delete these suppliers?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        deleteFormData(data).then((response: any) => {
          const { status, message } = response;
          if (status === 1) {
            Message.success('Successed');
          } else {
            Message.error(message);
          }
          setQueryModel({
            ...queryModel,
            pageIndex: 1,
          });
        });
      },
    });
  };

  // Export ASL
  const handleExportASL = () => {
    exportASL().then((response: any) => {
      const { headers, data } = response;
      const fileName = decodeURIComponent(headers['content-disposition'].split(';')[1].replace('filename=', ''));
      jsFileDownload(data, fileName);
    });
  };

  return (
    <div className='List'>
      <Card>
        <div onClick={() => handleStatusFilter()}>
          <Grid className={styles.grid} style={{ width: state.user.role === 1 ? '20%' : '25%' }}>
            <div className={styles.text}>Total</div>
            <div className={styles.count}>{formList.total}</div>
          </Grid>
        </div>
        <div onClick={() => handleStatusFilter([0])}>
          <Grid className={styles.grid} style={{ width: state.user.role === 1 ? '20%' : '25%' }}>
            <div className={styles.text}>Draft in process</div>
            <div className={styles.count}>{formList.toAudit}</div>
          </Grid>
        </div>
        <div onClick={() => handleStatusFilter([1, 4])}>
          <Grid className={styles.grid} style={{ width: state.user.role === 1 ? '20%' : '25%' }}>
            <div className={styles.text}>Under review</div>
            <div className={styles.count}>{formList.inTheReview}</div>
          </Grid>
        </div>
        <div onClick={() => handleStatusFilter([3])}>
          <Grid className={styles.grid} style={{ width: state.user.role === 1 ? '20%' : '25%' }}>
            <div className={styles.text}>Completed</div>
            <div className={styles.count}>{formList.completed}</div>
          </Grid>
        </div>
        {state.user.role === 1 && (
          <div onClick={() => handleStatusFilter([-1])}>
            <Grid className={styles.grid} style={{ width: state.user.role === 1 ? '20%' : '25%' }}>
              <div className={styles.text}>Deleted</div>
              <div className={styles.count}>{formList.total && formList.total - formList.toAudit - formList.inTheReview - formList.completed}</div>
            </Grid>
          </div>
        )}
      </Card>
      <div className={styles.wrapper}>
        {state.user.role === 1 && <Button type='primary' style={{ marginBottom: '24px' }} onClick={handleExportASL}>Export ASL</Button>}
        <Form
          form={form}
          name='listSearch'
          className={styles.search}
          onFinish={handleSearch}
        >
          <div className={styles.item_group}>
            <Item
              name='Supplier'
              label='Supplier'
              className={styles.item}
            >
              <Input placeholder='please input' autoComplete='off' />
            </Item>
            <Item
              name='EformType'
              label='Eform type'
              className={styles.item}
            >
              <Select
                placeholder='please select'
                options={[{
                  label: 'All',
                  value: '',
                }, {
                  label: 'TV-eFRM-16853',
                  value: 1,
                }, {
                  label: 'TV-eFRM-16856',
                  value: 2,
                }]}
              />
            </Item>
          </div>
          <div className={styles.btn_group}>
            <Button type='primary' htmlType='submit' loading={loading}>
              Search
            </Button>
            <Button
              style={{ marginLeft: '8px' }}
              loading={loading}
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </Form>
        <Table
          className={styles.table}
          loading={loading}
          rowSelection={state.user.role === 1 ? {
            selectedRowKeys,
            onChange: handleSelectChange,
          } : undefined}
          rowKey={(record) => `${record.eformType}_${record.id}`}
          columns={columns}
          dataSource={formList.entities}
          pagination={{
            pageSize: queryModel.pageCount,
            current: queryModel.pageIndex,
            total: formList.entityTotalCount,
            hideOnSinglePage: true,
          }}
          onChange={handleTableChange}
        />
      </div>
      {
        selectedRowKeys.length > 0 && state.user.role === 1 && (
          <div className={styles.footer}>
            <Button
              type='primary'
              danger
              onClick={handleDelete}
            >
              Delete ({selectedRowKeys.length})
            </Button>
          </div>
        )
      }
    </div>
  );
};

export default List;
