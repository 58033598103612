import { useState, useEffect, useRef, useContext, ReactNode } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  Link,
  Navigate,
} from 'react-router-dom';
import {
  Layout,
  Avatar,
  Modal,
  Spin,
  Steps,
  Dropdown,
  Menu,
  BackTop,
  Button,
  Input,
  Form,
  message as Message,
} from 'antd';
import {
  BarsOutlined,
  TableOutlined,
  FormOutlined,
  QuestionCircleOutlined,
  ProfileOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
  KeyOutlined,
} from '@ant-design/icons';
import {
  List,
  Tasks,
  Supplier,
  Questions,
  Logs,
  LogoutIcon,
  Account,
  Other,
  SetSecurityQuestions,
} from './../components';
import { authLogout, getHomeInfo, updatePassword } from './../api';
import styles from './../scss/modules/home.module.scss';
import { AppContext } from '../common';

type MenuItemChild = {
  key: string;
  title: string;
  path: string;
};

type MenuItem = {
  key: string;
  title: string;
  icon?: ReactNode;
  path?: string;
  children?: MenuItemChild[];
};

const Home = () => {
  const { state, dispatch } = useContext(AppContext);
  const [menuList, setMenuList] = useState<MenuItem[]>([
    {
      key: 'list',
      path: '/',
      title: 'List',
      icon: <TableOutlined />,
    }, {
      key: 'faqs',
      path: '/faqs',
      title: 'FAQ',
      icon: <QuestionCircleOutlined />,
    }, {
      key: 'logs',
      path: '/logs',
      title: 'Log',
      icon: <ProfileOutlined />,
    },
  ]);
  const { Header, Footer, Sider, Content } = Layout;
  const navigate = useNavigate();
  const { Item, SubMenu } = Menu;
  const [changePasswordForm] = Form.useForm();
  const [modalVisble, setModalVisble] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalSetSecurityQuestionsVisible, setModalSetSecurityQuestionsVisible] = useState(false);
  


  // Get user info and form template
  useEffect(() => {
    if (!state.isAuth) {
      navigate('auth');
      return;
    } else {
      if ([3, 4, 5, 6].indexOf(state.user.role) !== -1) {
        const task = {
          key: 'tasks',
          path: '/tasks',
          title: 'My task',
          icon: <BarsOutlined />,
        };
        setMenuList([task, ...menuList]);
      }

      if (!state.formTemplate.length) {
        dispatch({ type: 'loading', payload: true });
        getHomeInfo().then((response: any) => {
          const { model: { formList } } = response;
          if (formList && formList.length) {
            dispatch({ type: 'form', payload: formList });
          }
          dispatch({ type: 'loading', payload: false });
        }).catch(() => {
          dispatch({ type: 'loading', payload: false });
        });
      }
    }
  }, []);

  useEffect(() => {
    if (state.formTemplate.length && state.user.role === 2) {
      const children: MenuItemChild[] = [];
      state.formTemplate.forEach((item: any) => {
        children.push({
          key: item.name,
          path: `/supplier?eFormType=${item.name}`,
          title: item.name,
        });
      });
      const create = {
        key: 'create',
        title: 'Create',
        icon: <FormOutlined />,
        children,
      };
      const menus = [...menuList];
      menus.splice(1, 0, create);
      setMenuList([...menus]);
    }
  }, [state.formTemplate]);

  const logoutTimerIdRef = useRef(1);

  useEffect(() => {
    // Automatic logout after 15 minutes of user inactivity
    const autoLogout = () => {
      if (document.visibilityState === 'hidden') {
        const timeOutId = window.setTimeout(() => {
          authLogout().finally(() => {
            dispatch({ type: 'logout' });
          });
        }, 15 * 60 * 1000);
        logoutTimerIdRef.current = timeOutId;
      } else {
        window.clearTimeout(logoutTimerIdRef.current);
      }
    };

    document.addEventListener('visibilitychange', autoLogout);

    return () => {
      document.removeEventListener('visibilitychange', autoLogout);
    };
  }, []);

  /**
   * Render menu item
   * @param item - item of menuList
   * @returns {ReactNode}
   */
  const MenuItem = (item: any) => {
    return item.children ? (
      <SubMenu key={item.key} title={item.title} icon={item.icon}>
        {item.children.map((children: any) => MenuItem(children))}
      </SubMenu>
    ) : (
      <Item key={item.key} icon={item.icon}>
        {item.path ? <Link to={item.path}>{item.title}</Link> : item.title}
      </Item>
    );
  };

  // Lougout
  const handleLogout = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to log out?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        authLogout().finally(() => {
          dispatch({ type: 'logout' });
        });
      },
    });
  };
  
  // show ChangePassword modal
  const showChangePasswordModal = () => {
    changePasswordForm.resetFields();
    setModalVisble(true);
  };

  // close modal
  const handleModalCancel = () => {
    setModalVisble(false);
  };
  
  // handleChangePassword
  const handleChangePassword  = (values: any) => {
    const { oldPwd, newPwd } = values;
    setIsLoading(true);
    updatePassword({
      oldPwd,
      newPwd,
    }).then((response: any) => {
      const { status, message } = response;
      if (status === 1) {
        Message.success('Password has been changed, please log in with new password.');
        setIsLoading(false);
        setModalVisble(false);
        authLogout().finally(() => {
          dispatch({ type: 'logout' });
        });
      } else {
        setIsLoading(false);
        Message.error(message);
      }
    });
  };
  
  const openSetSecurityQuestionsModal = () => {
    setModalSetSecurityQuestionsVisible(true);
  };

  const closeSetSecurityQuestionsModel = ()=> {
    setModalSetSecurityQuestionsVisible(false);
  };

  return (
    <div className='home'>
      <BackTop style={{ zIndex: 999, right: '24px', bottom: '60px' }} />
      <Spin spinning={state.loading} tip='Loading...'>
        <Layout className={styles.layout}>
          <Sider
            className={styles.slider}
            width={208}
          >
            <Link className={styles.logo} to='/'>Sage</Link>
            <Menu
              openKeys={state.user.role === 2 ? ['create'] : undefined}
              selectedKeys={[state.menuKey]}
              expandIcon={<></>}
              mode='inline'
              theme='dark'
            >
              {menuList.map(item => MenuItem(item))}
            </Menu>
          </Sider>
          <Layout className={styles.wrapper}>
            <Header className={styles.header}>
              <div className={styles.info}>
                <div className={styles.title}>{state.pageTitle}</div>
                {state.supplier.current >= 0 && (
                  <Steps progressDot>
                    <Steps.Step title='Draft in process' status={state.supplier.current > 0 || state.supplier.role1.status !== 'wait' ? 'finish' : 'process'} />
                    <Steps.Step title={`Under review${state.supplier.role1.name ? '(' + state.supplier.role1.name + ')' : ''}`} status={state.supplier.role1.status} />
                    <Steps.Step title={`Under review${state.supplier.role2.name ? '(' + state.supplier.role2.name + ')' : ''}`}  status={state.supplier.role2.status} />
                    <Steps.Step title='Completed' status={state.supplier.current === 3 ? 'finish' : 'wait'} />
                  </Steps>
                )}
              </div>
              <div className={styles.user}>
                <Avatar icon={<UserOutlined />} />
                <Dropdown
                  placement='bottomCenter'
                  overlay={
                    <Menu>
                      {state.user.role === 1 && <Menu.Item key='users' icon={<UserOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}><Link to='/users'>Account</Link></Menu.Item>}
                      <Menu.Item key='changepassword' icon={<KeyOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>} onClick={showChangePasswordModal}>Change Password</Menu.Item>
                      <Menu.Item key='setsecurityquestions' icon={<QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />} onClick={openSetSecurityQuestionsModal}>Set Security Questions</Menu.Item>                      
                      <Menu.Item key='logout' icon={<LogoutIcon />} onClick={handleLogout}>&nbsp;&nbsp;Logout</Menu.Item>
                    </Menu>
                  }>
                  <span className={styles.name}>{state.user.name} <DownOutlined /></span>
                </Dropdown>
              </div>
            </Header>
            <Content>
              <Routes>
                <Route index element={<List />} />
                <Route path='tasks' element={<Tasks />} />
                <Route path='supplier' element={<Supplier />} />
                <Route path='faqs' element={<Questions />} />
                <Route path='logs' element={<Logs />} />
                <Route path='users' element={<Account />} />
                <Route path=':result' element={<Other />} />
                <Route path="*" element={<Navigate replace to='404' />} />
              </Routes>
            </Content>
            <Footer className={styles.footer}>All contents © Copyright Xian Janssen Pharmaceutical Ltd. 2012-2022. All Rights Reserved</Footer>
          </Layout>
        </Layout>
      </Spin>
      <Modal
        title='Change Password'
        visible={modalVisble}
        onCancel={handleModalCancel}
        footer={[
          <Button key='cancle' onClick={handleModalCancel}>
            Cancle
          </Button>,
          <Button form='changepassword' key='submit' type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>,
        ]}
        >
          <Form id='changepassword' form={changePasswordForm} onFinish={handleChangePassword}>
            <Form.Item
              name='oldPwd'
              label='Old password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  pattern: new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{10,20}'),
                  message: '密码需包含大写字母、小写字母、数字、特殊符号, 长度为10-20',
                },
              ]}
            >
              <Input.Password placeholder='please input' />
            </Form.Item>
            <Form.Item
              name='newPwd'
              label='New password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  pattern: new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{10,20}'),
                  message: '密码需包含大写字母、小写字母、数字、特殊符号, 长度为10-20',
                },
              ]}
            >
              <Input.Password placeholder='please input' />
            </Form.Item>
            <Form.Item
              name='confirmPwd'
              label='Confirm password'
              dependencies={['newPwd']}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPwd') === value) {
                      return Promise.resolve();
                    }
      
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder='please input' />
            </Form.Item>
          </Form>
        </Modal>
        {modalSetSecurityQuestionsVisible && <SetSecurityQuestions title='Set Security Questions' visible={modalSetSecurityQuestionsVisible} closeModal={closeSetSecurityQuestionsModel}></SetSecurityQuestions>}
    </div>
  );
};

export default Home;
