import axios from 'axios';
import { message as Message } from 'antd';

const axiosConfig = {
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
};

const request = axios.create(axiosConfig);

let isRefreshing = false;

// axios request interceptor
request.interceptors.request.use(
  config => {
    if (!config.url?.includes('auth')) {
      config.headers = {
        Authorization: `Bearer ${getLocalAccessToken()}`,
      };
    }
    
    if (config.url?.includes('download') || config.url?.includes('export') || config.url?.includes('aslreport')) {
      config.timeout = 1000 * 100; 
    }

    return config;
  },
  error => Promise.reject(error),
);

// axios response interceptor
request.interceptors.response.use(
  response => {
    const { data: { status, message, content }, config: { url } } = response;
    if (url === '/api/auth/logout') {
      return Promise.resolve(content);
    } else if (url === '/api/auth/getcaptcha') {
      return Promise.resolve(response.data);
    } else if (url?.includes('download') || url?.includes('export') || url?.includes('aslreport')) {
      if (status === void 0) {
        return Promise.resolve(response);
      } else if (status === '005') {
        Message.error(content.message);
        return Promise.reject(response);
      } else {
        Message.error(message);
        return Promise.reject(response);
      }
    } else {
      if (status === '001') {
        return Promise.resolve(content);
      } else {
        Message.error(message);
        return Promise.reject(response);
      }
    }
  },
  async (error) => {
    if (error.code === 'ECONNABORTED') {
      Message.error('Request timed out, please try again later.');
    } else {
      const { config, response: { status, statusText } } = error;
      if (status === 401 && !isRefreshing) {
        isRefreshing = true;

        try {
          const Token = getLocalAccessToken();
          const RefreshToken = getLocalRefreshToken();

          const { data } = await authRefreshToken({ Token, RefreshToken });
          const { content: { model: { accessToken, refreshAccessToken } } } = data;
          if (accessToken && refreshAccessToken) {
            isRefreshing = false;
            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('refresh_token', refreshAccessToken);

            return await request(config);
          } else {
            loginToAuth();
          }
        } catch (_error) {
          loginToAuth();
        }
      } else if (status === 413 && config.url?.includes('upload')) {
        Message.error('Only upload files in jpg, jpeg, png, doc, docx, xls, xlsx, pdf format, and the file size cannot exceed 5MB');
      } else {
        Message.error(statusText);
      }
    }

    return Promise.reject(error);
  },
);

const getLocalAccessToken = () => {
  const accessToken = localStorage.getItem('access_token') || '';
  return accessToken;
};

const getLocalRefreshToken = () => {
  const refreshToken = localStorage.getItem('refresh_token') || '';
  return refreshToken;
};

const loginToAuth = () => {
  Message.error('Your login information has expired, please log in again.');
  localStorage.clear();
  window.location.href = '/auth';
};

export const getCaptcha = () => {
  return request({
    url: '/api/auth/getcaptcha',
    method: 'get',
  });
};

export const getSecurityQuestion = () => {
  return request({
    url: '/api/accmanager/getsecurityquestion',
    method: 'get',
  });
};

export const getUserSecurityQuestions = (data: any) => {
  return request({
    url: '/api/accmanager/getUserSecurityQuestions',
    method: 'get',
    params: data,
  });
};

export const authLogin = (
  data: {
    loginId: string;
    password: string;
    captchaValue: string;
    captchaId: string;
  },
) => {
  return request({
    url: '/api/auth/login',
    method: 'post',
    data,
  });
};
export const authPreLogin = (data: {
  loginId: string;
}) => {
  return request({
    url: '/api/auth/prelogin',
    method: 'post',
    data,
  });
};

export const saveInitUserInfo = (
  data: {
    initUserCode: string;
    verifyCode: string;
    newPassword: string;
    question1Id: number;
    answer1: string;
    question2Id: number;
    answer2: string;
  },
) => {
  return request({
    url: '/api/accmanager/saveinituserinfo',
    method: 'post',
    data,
  });
};

export const authSSOLogin = (data: { code: string }) => {
  return request({
    url: '/api/auth/ssologin',
    method: 'post',
    data,
  });
};

export const authLogout = () => {
  return request({
    url: '/api/auth/logout',
    method: 'post',
  });
};

export const authRefreshToken = (
  data: {
    Token: string;
    RefreshToken: string;
  },
) => {
  return request({
    url: '/api/auth/refresh_token',
    method: 'post',
    data,
  });
};

export const getHomeInfo = () => {
  return request({
    url: '/api/home/info',
    method: 'get',
  });
};

export const getFormDataList = (
  data: {
    pageIndex: number;
    pageCount: number;
    'QueryModel.Supplier': string | undefined;
    'QueryModel.EformType': string | undefined;
    'SortModel.SortBy': string | undefined;
    'SortModel.AscOrDesc': string | undefined;
    'QueryModel.StatusList': string | undefined;
  },
) => {
  return request({
    url: '/api/formdata/list',
    method: 'get',
    params: data,
  });
};

export const getMyTasks = (data: any) => {
  return request({
    url: '/api/formdata/mytasks',
    method: 'get',
    params: data,
  });
};

export const getSupplierDetail = (typeId: string, data: any) => {
  return request({
    url: `/api/${typeId}/detail`,
    method: 'get',
    params: data,
  });
};

export const saveSupplierDraft = (typeId: string, data: any) => {
  return request({
    url: `/api/${typeId}/save`,
    method: 'post',
    data,
  });
};

export const submitSupplier = (typeId: string, data: any) => {
  return request({
    url: `/api/${typeId}/submit`,
    method: 'post',
    data,
  });
};

export const validateSupplier = (typeId: string, data: any) => {
  return request({
    url: `/api/${typeId}/validate`,
    method: 'post',
    data,
  });
};

export const changeSupplierStatus = (typeId: string, action: string, data: any) => {
  return request({
    url: `/api/${typeId}/${action}`,
    method: 'post',
    data,
  });
};

export const getAllReviewers = (typeId: string, data: any) => {
  return request({
    url: `/api/${typeId}/allreviewers`,
    method: 'get',
    params: data,
  });
};

export const deleteFormData = (data: any) => {
  return request({
    url: '/api/formdata/delete',
    method: 'post',
    data,
  });
};

export const getCommentList = (typeId: string, data: any) => {
  return request({
    url: `/api/${typeId}/comment/detail`,
    method: 'get',
    params: data,
  });
};

export const saveComment = (typeId: string, data: any) => {
  return request({
    url: `/api/${typeId}/comment/save`,
    method: 'post',
    data,
  });
};

export const getAttachList = (typeId: string, data: any) => {
  return request({
    url: `/api/${typeId}/attach/list`,
    method: 'get',
    params: data,
  });
};

export const getAttachDetailList = (typeId: string, data: any) => {
  return request({
    url: `/api/${typeId}/attach/detail`,
    method: 'get',
    params: data,
  });
};

export const fileUpload = (typeId: string, data: any) => {
  const { id, dataField, file } = data;
  let fd = new FormData();
  fd.append('id', id);
  fd.append('dataField', dataField);
  fd.append('file', file);
  return request({
    url: `/api/${typeId}/attach/upload`,
    method: 'post',
    data: fd,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const fileDownload = (typeId: string, data: any) => {
  return request({
    url: `/api/${typeId}/attach/download`,
    method: 'get',
    params: data,
    responseType: 'blob',
  });
};

export const fileDelete = (typeId: string, data: any) => {
  return request({
    url: `/api/${typeId}/attach/delete`,
    method: 'post',
    data,
  });
};

export const getLogList = (data: any) => {
  return request({
    url: '/api/log/list',
    method: 'get',
    params: data,
  });
};

export const getAccountList = (data: any) => {
  return request({
    url: '/api/AccManager/GetAccountList',
    method: 'get',
    params: data,
  });
};

export const createOrUpdateAccount = (data: any) => {
  return request({
    url: '/api/AccManager/CreateOrUpdateAccount',
    method: 'post',
    data,
  });
};

export const resetPassword = (data: any) => {
  return request({
    url: '/api/AccManager/ResetPassword',
    method: 'post',
    data,
  });
};

export const updatePassword = (data: any) => {
  return request({
    url: '/api/AccManager/UpdatePassword',
    method: 'post',
    data,
  });
};

export const forgetPassword = (data: any) => {
  return request({
    url: '/api/AccManager/ForgetPassword',
    method: 'post',
    data,
  });
};

export const forceChangePassword = (data: any) => {
  return request({
    url: '/api/AccManager/ForceChangePassword',
    method: 'post',
    data,
  });
};

export const saveUserSecurityQuestion = (
  data: {
    question1Id: number;
    answer1: string;
    question2Id: number;
    answer2: string;
  },
) => {
  return request({
    url: '/api/accmanager/saveusersecurityquestion',
    method: 'post',
    data,
  });
};

export const generateAndSendRegisterLink = (userId: string) => {
  return request({
    url: `/api/AccManager/GenerateAndSendRegisterLink?userId=${userId}`,
    method: 'post',
  });
};

export const exportSupplier = (typeId: string, data: any) => {
  return request({
    url: `/api/${typeId}/report/export`,
    method: 'get',
    params: data,
    responseType: 'arraybuffer',
  });
};

export const exportASL = () => {
  return request({
    url: '/api/formdata/aslreport',
    method: 'get',
    responseType: 'arraybuffer',
  });
};

export const getFaqList = () => {
  return request({
    url: '/api/faq/list',
    method: 'get',
  });
};

export const saveFaq = (
  data: {
    id?: number;
    title: string;
    content: string;
  },
) => {
  return request({
    url: '/api/faq/save',
    method: 'post',
    data,
  });
};

export const deleteFaq = (data: { id: number }) => {
  return request({
    url: '/api/faq/delete',
    method: 'post',
    data,
  });
};
