import { createContext, Dispatch } from 'react';
import { InitialStateType, AppAction } from './type';

const user = localStorage.getItem('user');
const isAuth = localStorage.getItem('access_token');
const formTemplate = localStorage.getItem('forms');
const faqs = localStorage.getItem('faqs');

export const initialState = {
  user: user ? JSON.parse(user) : {
    name: 'user',
    role: -1,
    id: -1,
  },
  loading: false,
  formTemplate: formTemplate ? JSON.parse(formTemplate) : [],
  isAuth: isAuth ? true : false,
  supplier: {
    current: -100,
    role1: {
      name: '',
      status: 'wait',
      id: null,
      current: 0,
    },
    role2: {
      name: '',
      status: 'wait',
      id: null,
      current: 0,
    },
    action: '',
  },
  pageTitle: 'List',
  menuKey: 'list',
  faqs: faqs ? JSON.parse(faqs) : [],
};

export const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<AppAction>;
}>({
  state: initialState,
  dispatch: () => null,
});
