import { useEffect, useContext, useState } from 'react';
import {
  Form,
  Input,
  Tabs,
  Button,
  Modal,
  message as Message,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './../scss/modules/questions.module.scss';
import { AppContext } from '../common';
import { getFaqList, saveFaq, deleteFaq } from '../api';

const Questions = () => {
  const quillOptions = {
    theme: 'snow',
    placeholder: 'Write something...',
    modules: {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'color': [] }, { 'background': [] }],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        ['link', 'image'],
        ['clean'],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
    formats: [
      'header', 'font',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'color', 'background',
      'list', 'bullet', 'indent',
      'link', 'image',
    ],
  };
  const { state, dispatch } = useContext(AppContext);
  const { TabPane } = Tabs;
  const { Item } = Form;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [editorHtml, setEditorHtml] = useState('');
  const [activeKey, setActiveKey] = useState('');
  const [modalProps, setModalProps] = useState<any>({
    title: '',
    visible: false,
    id: '',
  });

  useEffect(() => {
    if (state.isAuth) {
      dispatch({ type: 'menu', payload: { menuKey: 'faqs', pageTitle: '常见问题' } });

      if (!state.faqs.length) {
        dispatch({ type: 'loading', payload: true });
        getFaqList().then((response: any) => {
          const { model } = response;
          if (model.length) {
            setActiveKey(model[0].id.toString());
          }
          dispatch({ type: 'faqs', payload: model });
          dispatch({ type: 'loading', payload: false });
        }).catch(() => {
          dispatch({ type: 'loading', payload: false });
        });
      } else {
        setActiveKey(state.faqs[0].id.toString());
      }
    }
  }, []);

  const handleAddPane = () => {
    setModalProps({
      title: 'Add new tab',
      visible: true,
      id: '',
    });
    form.resetFields();
    setEditorHtml('');
  };

  const handleEditPane = (pane: any) => {
    setModalProps({
      title: 'Edit',
      visible: true,
      id: pane.id,
    });
    form.setFieldsValue({
      title: pane.title,
    });
    setEditorHtml(pane.content);
  };

  const handleDeletePane = (id: number) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to delete this pane?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        deleteFaq({ id }).then((response: any) => {
          const { status, message } = response;
          if (status === 1) {
            Message.success('Successed');
            const list = state.faqs.filter((item: any) => item.id !== id);
            if (list.length) {
              setActiveKey(list[0].id.toString());
            } else {
              setActiveKey('');
            }

            dispatch({ type: 'faqs', payload: list });
          } else {
            Message.error(message);
          }
        });
      },
    });
  };

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  const handleModalCancel = () => {
    setModalProps({
      title: '',
      visible: false,
      id: '',
    });
  };

  const handleQuillChange = (html: any) => {
    setEditorHtml(html);
  };

  const handleFinished = (values: any) => {
    const data = modalProps.id ? {
      id: modalProps.id,
      title: values.title,
      content: editorHtml,
    } : {
      title: values.title,
      content: editorHtml,
    };
    setIsLoading(true);
    saveFaq(data).then((response: any) => {
      const { status, message, model } = response;
      if (status) {
        Message.success('Successed');
        if (modalProps.id) {
          const list = [...state.faqs];
          list.map((item: any) => {
            if (item.id === modalProps.id) {
              item.title = values.title;
              item.content = editorHtml;
            }
            return item;
          });
          dispatch({ type: 'faqs', payload: list });
        } else {
          const list = [...state.faqs, {
            id: model.id,
            title: values.title,
            content: editorHtml,
          }];
          setActiveKey(model.id.toString());
          dispatch({ type: 'faqs', payload: list });
        }
        setModalProps({
          title: '',
          visible: false,
          id: '',
        });
      } else {
        Message.error(message);
      }
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  };

  return (
    <div className={styles.wrapper}>
      {state.user.role === 1 && <Button style={{ marginBottom: '20px' }} onClick={handleAddPane}>Add new tab</Button>}
      {state.faqs.length > 0 &&
        <Tabs
          tabPosition='left'
          className={styles.tab}
          activeKey={activeKey}
          onChange={handleTabChange}
        >
          {
            state.faqs.map((pane: any) => (
              <TabPane tab={pane.title} key={pane.id.toString()}>
                <div className={styles.pane}>
                  {state.user.role === 1 && (
                    <div className={styles.buttons}>
                      <Button type='primary' style={{ marginRight: '20px' }} onClick={() => handleEditPane(pane)}>Edit</Button>
                      <Button type='primary' danger onClick={() => handleDeletePane(pane.id)}>Delete</Button>
                    </div>
                  )}
                  <h2>{pane.title}</h2>
                  <div className='pane-content' dangerouslySetInnerHTML={{ __html: pane.content }}></div>
                </div>
              </TabPane>
            ))
          }
        </Tabs>
      }
      <Modal
        title={modalProps.title}
        visible={modalProps.visible}
        onCancel={handleModalCancel}
        footer={[
          <Button key='cancle' onClick={handleModalCancel}>
            Cancle
          </Button>,
          <Button form='form' key='submit' type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>,
        ]}
        >
          <Form id='form' form={form} onFinish={handleFinished}>
            <Item
              name='title'
              label='Title'
              rules={[
                {
                  required: true,
                  message: 'Please input title!',
                },
              ]}
            >
              <Input placeholder='please input' autoComplete='off' />
            </Item>
            <ReactQuill
              {...quillOptions}
              value={editorHtml}
              onChange={handleQuillChange}
            />
          </Form>
        </Modal>
    </div>
  );
};

export default Questions;
