import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Layout,
  Form,
  Input,
  Button,
  Tabs,
  Modal,
  notification,
} from 'antd';
import {
  UserOutlined,
  LockOutlined,
  SafetyOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  authLogin,
  authPreLogin,
  authSSOLogin,
  getCaptcha,
} from './../api';
import Logo from './../images/logo.svg';
import styles from './../scss/modules/login.module.scss';
import { useQuery, AppContext, LoginType } from '../common';
import { ForgetPassword, ForceChangePassword } from '../components';

const Login = () => {
  const { state, dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [codeBase64Img, setCodeBase64Img] = useState('');
  const [captchaId, setCaptchaId] = useState('');
  const { Content, Footer } = Layout;
  const { Item } = Form;
  const { TabPane } = Tabs;
  const navigate = useNavigate();
  const code = useQuery().get('code') || ''; 
  const [modalVisible, setModalVisible] = useState(false);
  const [modalForceChangePasswordVisible, setModalForceChangePasswordVisible] = useState(false);
  
  useEffect(() => {
    if (state.isAuth) {
      navigate('/');
      return;
    } else if (code) {
      handleSSOLogin();
    } else {
      handleGetCaptcha();
    }
  }, []);

  const handleShowWarning = (onOk: any, onCancel: any) => {
    let confirmed = localStorage.getItem('user_confirmed_warning_notice');
    if (confirmed === 'true') {
      onOk();
    } else {
      Modal.confirm({
        title: 'WARNING NOTICE',
        icon: <WarningOutlined />,
        content: 'This system is restricted solely to Johnson & Johnson users for authorized business only. Any actual or attempted unauthorized access, use or modification of this system is strictly prohibited by Johnson & Johnson. Unauthorized users are subject to Johnson & Johnson disciplinary proceedings and/or criminal and civil penalties under state, federal or other applicable domestic and foreign laws. The use of this system may be monitored and recorded for administrative and security reasons. If such monitoring and/or recording reveal possible evidence of criminal activity, Johnson & Johnson may provide the evidence of such monitoring to law enforcement officials.',
        okText: 'I Confirm',
        cancelText: 'No',
        onOk: () => {
          onOk();
          localStorage.setItem('user_confirmed_warning_notice', 'true');
        },
        onCancel: onCancel,
      });
    }
  };
  /**
   * Login successful, set user info, accessToken and navigate to homepage
   * @param response - response form http request
   */
  const handleLoginSeccess = (response: any) => {
    const { model: { accessToken, refreshAccessToken, name, role, id } } = response;
    dispatch({
      type: 'login',
      payload: {
        user: { name, role, id },
        isAuth: true,
        accessToken,
        refreshAccessToken,
      },
    });
    setLoading(false);
    const url = [3, 4, 5, 6].indexOf(role) !== -1 ? '/tasks' : '/';
    navigate(url);
  };

  /**
   * 
   * @param {LoginType} params - loginId, password and captchaValue
   */
  const handlePasswordLogin = (params: LoginType) => {
    setLoading(true);
    handleShowWarning(
      () => {
        authPreLogin({ loginId: params.loginId }).then((response: any) => {
          if (response.model.mustChangePassword === true) {
            setLoading(false);
            openForceChangePasswordModel();
          } else {
            authLogin({ ...params, captchaId }).then((response2: any) => {
              handleLoginSeccess(response2);
              if (response.model.needChangePassword === true) {
                openPasswordExpireNotification(response.model.passwordExpiredIn);
              }
              if (response.model.needSetSecurityQuestions === true) {
                openSetSecurityQuestionsNotification();
              }
            }).catch(() => {
              setLoading(false);
              handleGetCaptcha();
            });
          }
        }).catch(() => {
          setLoading(false);
          handleGetCaptcha();
        });        
      },
      () => setLoading(false),
    );
  };

  // SSO login
  const handleSSOLogin = () => {
    setLoading(true);
    handleShowWarning(
      () => authSSOLogin({ code }).then((response: any) => {
        handleLoginSeccess(response);
      }).catch(() => {
        setLoading(false);
      }), 
      () => setLoading(false),
    );
  };

  // Redirect to SSO
  const redirectToSSO = () => {
    const uri = `https://fedlogin.jnj.com/as/authorization.oauth2?client_id=smarttool&response_type=code&redirect_uri=${window.location.origin}/auth&scope=address email openid phone profile`;
    window.location.href = decodeURIComponent(uri);
  };

  // Get captcha
  const handleGetCaptcha = () => {
    getCaptcha().then((response: any) => {
      const { id, data, mimeType } = response;
      setCaptchaId(id);
      setCodeBase64Img(`data:${mimeType};base64, ${data}`);
      setLoading(false);
    }).catch(() => {
      setCaptchaId('');
      setCodeBase64Img('');
    });
  };

  const openForgetPasswordModel = () => {
    setModalVisible(true);
  };
  const closeForgetPasswordModel = () => {
    setModalVisible(false);
  };
  
  const openForceChangePasswordModel = () => {
    setModalForceChangePasswordVisible(true);
  };
  const closeForceChangePasswordModel = () => {
    setModalForceChangePasswordVisible(false);
  };

  const openPasswordExpireNotification = (days: Number) => {
    notification.open({
      message: 'Please change your password',
      description: `Your password will expire in ${days} days, please change your password.`,
      duration: 0,
      icon: <WarningOutlined />,
    });
  };
  const openSetSecurityQuestionsNotification = () => {
    notification.open({
      message: 'Please set your security questions',
      description: 'Please set your security questions.',
      duration: 0,
      icon: <WarningOutlined />,
    });
  };

  return (
    <div className='login'>
      <Layout className={styles.layout}>
        <Content className={styles.content}>
          <div className={styles.title}>
            <img className={styles.logo} src={Logo} alt='Supplier Auxiliary Governance e-form' />
          </div>
          <Tabs defaultActiveKey={code ? '2' : ''}>
            <TabPane tab='Password to login' key='1'>
              <Form
              className={styles.form}
              onFinish={handlePasswordLogin}
            >
              <Item
                name='loginId'
                rules={[
                  {
                    required: true,
                    message: 'Please input your Username!',
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className={styles.icon} />}
                  size='large'
                  placeholder='Username'
                  autoComplete='off'
                />
              </Item>
              <Item
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Please input your Password!',
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className={styles.icon} />}
                  size='large'
                  type='password'
                  placeholder='Password'
                  autoComplete='off'
                />
              </Item>
              <div className={styles.captcha}>
                <Item
                  style={{ width: '60%' }}
                  name='captchaValue'
                  rules={[
                    {
                      required: true,
                      message: 'Please input verification code!',
                    },
                  ]}
                >
                  <Input
                    prefix={<SafetyOutlined className={styles.icon} />}
                    size='large'
                    placeholder='Code'
                    autoComplete='off'
                  />
                </Item>
                <div className={styles.code} onClick={handleGetCaptcha}>
                  {codeBase64Img ? <img style={{ display: 'block' }} src={codeBase64Img} alt='验证码' /> : '点击刷新'}
                </div>
              </div>
              <Item>
                <Button type='primary' htmlType='submit' block size='large' loading={loading}>
                  Log in
                </Button>
                <Button type='link' size='large' onClick={openForgetPasswordModel}>
                  Forget password
                </Button>
              </Item>
            </Form>
            </TabPane>
            <TabPane tab='J&J user login' key='2'>
              <div className={styles.form}>
                <p className={styles.info}>Please click "login" button redirect to J&J login page to login.</p>
                <Button type='primary' block size='large' onClick={redirectToSSO} loading={loading}>
                  Log in
                </Button>
              </div>
            </TabPane>
          </Tabs>
        </Content>
        <Footer className={styles.footer}>All contents © Copyright Xian Janssen Pharmaceutical Ltd. 2012-2022. All Rights Reserved</Footer>
      </Layout>
      {modalVisible && <ForgetPassword  visible={modalVisible} closeModal={closeForgetPasswordModel}></ForgetPassword>}
      {modalForceChangePasswordVisible && <ForceChangePassword title='Change Password' visible={modalForceChangePasswordVisible} closeModal={closeForceChangePasswordModel}></ForceChangePassword>}
    </div>
  );
};

export default Login;
